/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/


import httpClient from '../../api/httpClient'

export default {
  actions: {    
    async fetchExtraHosts(ctx, netId) {
      try {
        const res = await httpClient.get(`/extra-hosts?blockchain_net_id=${netId}`, {blockchain_net_id: netId})
        console.log('SUCCESS GET /extra-hosts: ', res)
        ctx.commit('updateExtraHosts', res.data)

        return Promise.resolve(res.data)

      } catch (err) {
        console.log('ERROR GET /extra-hosts: ', err)

        return Promise.reject(err)
      }
    },
    async postExtraHost(ctx, data) {
      try {
        const res = await httpClient.post('/extra-hosts', data)
        console.log('SUCCESS POST /extra-hosts: ', res)
        
        await this.dispatch('fetchExtraHosts', res.data.blockchain_net_id)

        return Promise.resolve(res.data)

      } catch (err) {
        console.log('ERROR POST /extra-hosts: ', err)

        return Promise.reject(err)

      }
    },
    async deleteExtraHost(ctx, {endpointId, netId}) {
      try {
        const res = await httpClient.delete(`/extra-hosts/${endpointId}`)
        console.log(`SUCCESS DELETE /extra-hosts/${endpointId}: `, res)

        await this.dispatch('fetchExtraHosts', netId)
        
        return Promise.resolve(res.data)

      } catch (err) {
        console.log(`ERROR DELETE /extra-hosts/${endpointId}: `, err)

        return Promise.reject(err)
      }
    }
  },
  mutations: {
    updateExtraHosts(state, hosts) {
      state.extraHosts = hosts
    }
  },  
  state: {
    extraHosts: {},
  },  
  getters: {
    extraHosts: state => {
      return state.extraHosts
    },
    getExtraHosts: state => orgId => {
      return state.extraHosts[orgId]
    },
  },
}