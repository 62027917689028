/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import httpClient from "../../api/httpClient";

export default {
  actions: {
    async fetchTopicLog(ctx, topicId) {
      try {
        const res = await httpClient.get(`/topics/${topicId}/log`);
        console.log(`SUCCESS GET /topics/${topicId}/log: `, res);
        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR GET /topics/${topicId}/log: `, err);
        return Promise.reject(err);
      }
    },
    async fetchTopicByNet(ctx, netId) {
      try {
        const res = await httpClient.get(`/topics/by-object/${netId}`);
        console.log(`SUCCESS GET /topics/by-object/${netId}`, res);
        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR GET /topics/by-object/${netId}: `, err);
        return Promise.reject(err);
      }
    },
    async fetchTopics() {
      try {
        const res = await httpClient.get(`/topics`, {
          params: {
            view: "notifications",
          },
        });
        console.log(`SUCCESS GET /topics/ `, res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR GET /topics/ `, err);

        return Promise.reject(err);
      }
    },
    initTopics(ctx, params) {
      const websocket = new WebSocket(params.url);

      websocket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        const message = params.vm.$i18n.t(`message.${data.type}`);
        let notification = null;

        if (data.success) {
          notification = {
            variant: "success",
            href: `/topics/${data.topic_id}`,
            title: params.vm.$t("common.operationSuccess"),
          };
          this.dispatch("reloadToken");
        } else {
          notification = {
            variant: "danger",
            href: `/topics/${data.topic_id}`,
            title: params.vm.$t("common.operationFailed"),
          };
          ctx.commit("setTopicFailed", true);
        }

        if (typeof ctx.state.subscriptions[data.topic_id] == "function") {
          ctx.state.subscriptions[data.topic_id](data)
        }

        if (typeof ctx.state.subscriptions[data.type] == "function") {
          ctx.state.subscriptions[data.type](data)
        }


        params.vm.$bvToast.toast(message, notification);
      };

      ctx.commit("setConnection", websocket);
    },
    disposeTopics({ commit, state }) {
      if (!state.connection) {
        return;
      }

      state.connection.close();
      commit("setConnection", null);
    },
    toggleTopicStatus(ctx) {
      ctx.commit("setTopicFailed", false);
    },
    subscribeTopic(ctx, { topicId, handler, payload, once = true }) {
      ctx.commit("subscribe", { topicId, handler, payload, once });
    },
    subscribeOnTopicByType(ctx, { topicType, handler }) {
      ctx.commit("subscribeByType", { topicType, handler });
    },
    unsubscribeTopic(ctx, topicId) {
      ctx.commit("unsubscribe", topicId);
    },
    unsubscribeByType(ctx, type) {
      ctx.commit("unsubscribeByType", type);
    },
  },
  mutations: {
    setTopicFailed(state, is) {
      state.isTopicFailed = is;
    },
    subscribe(state, { topicId, handler, payload, once }) {
      state.subscriptions[topicId] = { handler, payload, once };
    },
    subscribeByType(state, { topicType, handler }) {
      state.subscriptionsByTopicType[topicType] = handler;
    },
    unsubscribe(state, topicId) {
      if (!state.subscriptions[topicId]) {
        return;
      }

      delete state.subscriptions[topicId];
    },
    unsubscribeByType(state, type) {
      if (!state.subscriptions[type]) {
        return;
      }

      delete state.subscriptions[type];
    },
    setConnection(state, connection) {
      state.connection = connection;
    },
  },
  state: {
    isTopicFailed: false,
    subscriptions: {},
    subscriptionsByTopicType: {},
    connection: null,
    wsConnected: false,
    wsError: "",
  },
  getters: {
    getTopicFailed: (state) => {
      return state.isTopicFailed;
    },
  },
};
