/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import CoreuiVue from "@coreui/vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BVToastPlugin } from "bootstrap-vue";
import "core-js/stable";
import Vue from "vue";
import VueBootstrapToasts from "vue-bootstrap-toasts";
import Vuelidate from "vuelidate";
import App from "./App";
import { iconsSet as icons } from "./assets/icons/icons.js";
import i18n from "./i18n";
import router from "./router";
import store from "./store";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDoubleRight,
  faAngleDown,
  faAngleUp,
  faArrowLeft,
  faBars,
  faCheck,
  faCheckCircle,
  faCircle,
  faClock,
  faCode,
  faDatabase,
  faDownload,
  faExclamationCircle,
  faFileCode,
  faFolder,
  faGlobe,
  faPlusSquare,
  faRocket,
  faSync,
  faThumbsUp,
  faTimes,
  faUpload,
  faWindowClose,
  faWrench
} from "@fortawesome/free-solid-svg-icons";
import { capitalizeFirst } from "./filters/stringFilters";

library.add(
  faArrowLeft,
  faBars,
  faAngleUp,
  faAngleDown,
  faAngleDoubleRight,
  faDatabase,
  faDownload,
  faExclamationCircle,
  faCheckCircle,
  faGlobe,
  faClock,
  faCircle,
  faSync,
  faPlusSquare,
  faRocket,
  faThumbsUp,
  faWrench,
  faUpload,
  faCheck,
  faWindowClose,
  faCode,
  faFileCode,
  faFolder,
  faTimes
);

import clickOutside from "./directives/click-outside";

Vue.component("fa-icon", FontAwesomeIcon);

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(Vuelidate);
Vue.use(VueBootstrapToasts);
Vue.use(BVToastPlugin);

// filters
Vue.filter("capitalizeFirst", capitalizeFirst);
Vue.directive("click-outside", clickOutside);

Vue.prototype.$log = console.log.bind(console);

store.commit("initializeStore");

new Vue({
  el: "#app",
  i18n,
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
});
