function callSubscriber(subscriberInfo, data) {
  try {
    const { handler, extraPayload } = subscriberInfo;

    if (typeof handler !== "function") {
      return;
    }
    handler({ ...data, ...extraPayload });
  } catch (error) {
    console.error(error);
  }
}

import { getEnv } from "../../services";

export default {
  state: {
    subscribers: {},
    connection: null,
    buffer: [],
    bufferSize: getEnv().VUE_APP_WS_BUFFER_SIZE,
  },
  actions: {
    initWebsocket({ commit, state }, { url, showNotifiation }) {
      const websocket = new WebSocket(url);

      websocket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        commit("pushToBuffer", data);
        showNotifiation(data);

        state.subscribers[data.topic_id]?.forEach((subscriber) =>
          callSubscriber(subscriber, data)
        );
        state.subscribers[data.type]?.forEach((subscriber) =>
          callSubscriber(subscriber, data)
        );
      };

      commit("setConnection", websocket);
    },
    subscribe({ commit, state }, { key, handler, extraPayload }) {
      commit("addSubscriber", { key, handler, extraPayload });

      const subscribtionIndex = state.subscribers[key].length - 1;

      const event = state.buffer.find((item) => item.topic_id === key);

      if (event) {
        state.subscribers[key]?.forEach((subscriber) =>
          callSubscriber(subscriber, event)
        );
      }

      return {
        unsubscribe() {
          state.subscribers[key].splice(subscribtionIndex, 1);
          if (state.subscribers[key].length === 0) {
            delete state.subscribers[key];
          }
        },
      };
    },
    disposeWebsocket({ commit, state }) {
      if (state.connection) {
        state.connection.close();
        commit("setConnection", null);
      }

      commit("clearBuffer");
      commit("clearSubscribers");
    },
  },
  mutations: {
    setConnection(ctx, connection) {
      ctx.connection = connection;
    },
    addSubscriber(ctx, { key, handler, extraPayload }) {
      if (!ctx.subscribers[key]) {
        ctx.subscribers[key] = [];
      }

      ctx.subscribers[key].push({ handler, extraPayload });
    },
    pushToBuffer(state, data) {
      if (state.buffer.length > state.bufferSize) {
        state.buffer.shift();
      }

      state.buffer.push(data);
    },
    clearBuffer(state) {
      state.buffer = [];
    },
    clearSubscribers(state) {
      state.subscribers = {};
    },
  },
};
