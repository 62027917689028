/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import { ServiceUpdateStatus } from "@/views/hauberk/services/constants";
import httpClient from "../../api/httpClient";

export default {
  actions: {
    async fetchServices(ctx) {
      try {
        let res = await httpClient.get("/admin-connectors");
        console.log("SUCCESS /admin-connectors: ", res);
        ctx.commit("updateServices", res.data);

        if (Array.isArray(res.data)) {
          res.data.map((server) => {
            if (
              server.update_status === ServiceUpdateStatus.requested.code ||
              server.update_status === ServiceUpdateStatus.draft.code
            ) {
              ctx.dispatch("openWsConnectionByServerId", server.id);
            }
          });
        }

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR GET /channels: ", err);

        return Promise.reject(err);
      }
    },
    async createServer(ctx, data) {
      try {
        const res = await httpClient.post("/admin-connectors", data);
        console.log("SUCCESS POST /admin-connectors: ", res);

        ctx.commit("addService", res.data);

        await ctx.dispatch("openWsConnectionByServerId", res.data.id);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR POST /admin-connectors: ", err);

        return Promise.reject(err);
      }
    },
    async openWsConnectionByServerId(ctx, serverId) {
      const topicId = await ctx.dispatch("getTopicByIdForService", serverId);

      const subscription = ctx.dispatch("subscribe", {
        key: topicId,
        handler: (data) => {
          try {
            if (data.success) {
              ctx.commit("changeStatusToSuccess", data.topic_id);
            } else {
              const errorMessage = data.errors.join("\n");
              ctx.commit("changeStatusToError", {
                topicId: data.topic_id,
                errorMessage,
              });
            }
          } finally {
            subscription.unsubscribe();
          }
        },
      });
    },
    async updateServer(ctx, { serverId, data }) {
      try {
        const res = await httpClient.put(`/admin-connectors/${serverId}`, data);
        console.log(`SUCCESS PUT /admin-connectors/${serverId}: `, res);
        ctx.commit("updateService", res.data);
        ctx.dispatch("openWsConnectionByServerId", serverId);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR PUT /admin-connectors/${serverId}: `, err);

        return Promise.reject(err);
      }
    },
    async deleteServer(ctx, serverId) {
      try {
        const res = await httpClient.delete(`/admin-connectors/${serverId}`);
        console.log(`SUCCESS DELETE /admin-connectors/${serverId}: `, res);
        ctx.commit("removeServer", serverId);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR DELETE /admin-connectors/${serverId}: `, err);

        return Promise.reject(err);
      }
    },
    async deleteEndpointFromServer(ctx, endpointId) {
      try {
        const res = await httpClient.delete(`/endpoints/${endpointId}`);
        console.log(`SUCCESS DELETE /endpoints/${endpointId}: `, res);
        ctx.commit("removeEndpoint", endpointId);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR DELETE /endpoints/${endpointId}: `, err);
        return Promise.reject(err);
      }
    },
    async getTopicByIdForService(ctx, serverId) {
      try {
        const res = await httpClient.get(`/topics/by-object/${serverId}`);

        console.log(`SUCCESS GET /topics/by-object/${serverId}: `, res);

        const serverIdTopicId = {
          serverId: serverId,
          topicId: res.data.topic_id,
        };

        ctx.commit("addTopicIdToServer", serverIdTopicId);

        return Promise.resolve(res.data.topic_id);
      } catch (err) {
        console.log(`ERROR GET /topics/by-object//${serverId}: `, err);

        return Promise.reject(err);
      }
    },
    async getEnvVariables(_, id) {
      const response = await httpClient.post(
        `/endpoints/${id}/docker/get-environment-variables`
      );
      return response.data.topic_id;
    },
    async setEnvVariables(_, { id, variables }) {
      try {
        const body = variables.reduce(
          (state, [key, value]) => ({ ...state, ...{ [key]: value } }),
          {}
        );

        const response = await httpClient.post(
          `/endpoints/${id}/docker/put-environment-variables`,
          {
            variables: body,
          }
        );

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  mutations: {
    updateServices(state, services) {
      state.services = services;
    },
    addService(state, service) {
      state.services = [...state.services, service];
    },
    updateService(state, service) {
      state.services = state.services.map((item) => {
        if (item.id === service.id) {
          return { ...item, ...service };
        }
        return item;
      });
    },
    removeServer(state, serverId) {
      state.services = state.services.filter((item) => item.id !== serverId);
    },
    removeEndpoint(state, endpointId) {
      state.services.forEach((service) => {
        service.endpoints = service.endpoints.filter(
          (endpoint) => endpoint.id !== endpointId
        );
      });
    },
    addTopicIdToServer(state, { serverId, topicId }) {
      let server = state.services.find((server) => server.id === serverId);
      if (server) {
        server = { ...server, topicId };
        state.services = [
          ...state.services.filter((item) => item.id !== serverId),
          server,
        ];
      }
    },
    changeStatusToSuccess(state, topicId) {
      let server = state.services.find((server) => server.topicId === topicId);
      if (server) {
        const serverId = server.id;
        server = { ...server, update_status: ServiceUpdateStatus.success.code };
        state.services = [
          ...state.services.filter((item) => item.id !== serverId),
          server,
        ];
      }
    },
    changeStatusToError(state, { topicId, errorMessage }) {
      let server = state.services.find((server) => server.topicId === topicId);
      if (server) {
        const serverId = server.id;
        server = {
          ...server,
          update_status: ServiceUpdateStatus.error.code,
          errorMessage,
        };
        state.services = [
          ...state.services.filter((item) => item.id !== serverId),
          server,
        ];
      }
    },
    deleteErrorMessageFromServer(state, serverId) {
      let server = state.services.find((server) => server.id === serverId);
      if (server && server.errorMessage != null) {
        delete server.errorMessage;
        state.services = [
          ...state.services.filter((item) => item.id !== serverId),
          server,
        ];
      }
    },
  },
  state: {
    services: [],
  },
  getters: {
    allServices: (state) => {
      return state.services;
    },
    getTopicId: (state) => (serverId) => {
      const server = state.services.find((server) => server.id === serverId);
      return server.topic_id;
    },
  },
};
