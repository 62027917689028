
import { setLicenseStatus } from "../../services/locaStorageProvider";

function handleSetLicenseAcepted(mutation, _) {
  
   if (mutation.type !== "setLicenseAccepted") {
    return;
  }

  setLicenseStatus(mutation.payload);
}

export { handleSetLicenseAcepted };

