import { HAUBERK_STORE } from "../constants";

function handleSetHostsUpdateStatus(mutation, _) {
  
   if (mutation.type !== "setHostsUpdateStatus") {
    return;
  }
  const store = JSON.parse(localStorage.getItem(HAUBERK_STORE)) ?? {};
  store.endpoint = {
    ...store.endpoint,
    ...{ hostsUpdateStatus: mutation.payload },
  };

  localStorage.setItem(HAUBERK_STORE, JSON.stringify(store));
}

export { handleSetHostsUpdateStatus };
