/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import Vue from "vue";
import Router from "vue-router";
import i18n from "../i18n";
import store from "../store/index";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Login
const Login = () => import("@/views/pages/Login");
const RestorePassword = () => import("@/views/pages/RestorePassword");
const ChangeDefaultPassword = () =>
  import("@/views/pages/ChangeDefaultPassword");

// Dashboard
const Dashboard = () => import("@/views/Dashboard");

// Networks
const Networks = () => import("@/views/hauberk/networks/Networks");
const Network = () => import("@/views/hauberk/networks/Network");
const NetworkCreate = () => import("@/views/hauberk/networks/NetworkCreate");
const NetworkDraft = () => import("@/views/hauberk/networks/NetworkDraft");
const NetworkConnect = () => import("@/views/hauberk/networks/NetworkConnect");
const ChangeTransaction = () =>
  import("@/views/hauberk/networks/ChangeTransaction");

// Channels
const Channels = () => import("@/views/hauberk/channels/Channels");
const Channel = () => import("@/views/hauberk/channels/Channel");
const ChannelCreate = () => import("@/views/hauberk/channels/ChannelCreate");
const ChannelDraft = () => import("@/views/hauberk/channels/ChannelDraft");
const ChannelImport = () => import("@/views/hauberk/channels/ChannelImport");

const ChangeConfiguration = () =>
  import("@/views/hauberk/channels/transaction/ChangeConfiguration");

// Topics
const Topic = () => import("@/views/hauberk/topics/Topic");

// Services
const ServerList = () => import("@/views/hauberk/services/ServerList");
const ServiceDetail = () => import("@/views/hauberk/services/ServiceDetail");

// SmartContracts
const SmartContracts = () =>
  import("@/views/hauberk/smartContracts/SmartContracts");
const SmartContract = () =>
  import("@/views/hauberk/smartContracts/SmartContract");
const SmartContractImport = () =>
  import("@/views/hauberk/smartContracts/SmartContractImport");
const SmartContractCreate = () =>
  import("@/views/hauberk/smartContracts/SmartContractCreate");
const SmartContractSourceCode = () =>
  import("@/views/hauberk/smartContracts/SmartContractSourceCode");

// Endpoints
const Endpoint = () => import("@/views/hauberk/services/endpoints/Endpoint");

Vue.use(Router);

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/dashboard",
      name: i18n.t("menu.main"),
      component: TheContainer,
      children: [
        {
          path: "dashboard",
          name: i18n.t("menu.main"),
          component: Dashboard,
        },
        {
          path: "topics/:id",
          name: i18n.t("menu.tasks"),
          component: Topic,
        },
        {
          path: "networks",
          redirect: "/networks/list",
          name: i18n.t("menu.networks"),
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "list",
              name: i18n.t("menu.allNetworks"),
              component: Networks,
            },
            {
              path: "connect",
              name: i18n.t("menu.connectNetwork"),
              component: NetworkConnect,
            },
            {
              path: "create",
              name: i18n.t("menu.createNetwork"),
              component: NetworkCreate,
            },
            {
              path: ":id",
              name: i18n.t("menu.network"),
              component: Network,
            },
            {
              path: ":id/draft",
              name: i18n.t("menu.editDraft"),
              component: NetworkDraft,
            },
            {
              path: ":id/edit",
              name: i18n.t("menu.changeNetwork"),
              component: NetworkDraft,
            },
            {
              path: ":id/change-config",
              name: i18n.t("menu.changeConfig"),
              component: ChangeTransaction,
            },
          ],
        },
        {
          path: "channels",
          redirect: "/channels/list",
          name: i18n.t("menu.channels"),
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "list",
              name: i18n.t("menu.allChannels"),
              component: Channels,
            },
            {
              path: "import",
              name: i18n.t("menu.importChannel"),
              component: ChannelImport,
            },
            {
              path: "create",
              name: i18n.t("menu.createChannel"),
              component: ChannelCreate,
            },
            {
              path: ":id",
              name: i18n.t("menu.channel"),
              component: Channel,
            },
            {
              path: ":id/change-config",
              name: i18n.t("menu.changeConfig"),
              component: ChangeConfiguration,
            },
            {
              path: ":id/draft",
              name: i18n.t("menu.editDraft"),
              component: ChannelDraft,
            },
          ],
        },
        {
          path: "services",
          name: i18n.t("menu.services"),
          component: ServerList,
        },
        {
          path: "endpoints",
          redirect: "/endpoints/:id",
          name: i18n.t("menu.services"),
          component: ServiceDetail,
          children: [
            {
              path: ":id",
              name: i18n.t("menu.service"),
              component: Endpoint,
            },
          ],
        },
        {
          path: "smart-contracts",
          name: i18n.t("menu.smartContracts"),
          redirect: "/smart-contracts/list",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "list",
              name: i18n.t("menu.allSmartContracts"),
              component: SmartContracts,
            },
            {
              path: "import",
              name: i18n.t("menu.importSmartContract"),
              component: SmartContractImport,
            },
            {
              path: "create",
              name: i18n.t("menu.createSmartContract"),
              component: SmartContractCreate,
            },
            {
              path: ":id",
              name: i18n.t("menu.smartContract"),
              component: SmartContract,
            },
            {
              path: ":id/source-code",
              name: i18n.t("menu.smartContract"),
              component: SmartContractSourceCode,
            },
          ],
        },
      ],
    },
    {
      path: "/login",
      name: i18n.t("menu.login"),
      component: Login,
    },
    {
      path: "/password-reset",
      name: i18n.t("menu.restorePassword"),
      component: RestorePassword,
    },
    {
      path: "/change-default-password/:userLogin",
      name: "changeDefaultPassword",
      component: ChangeDefaultPassword,
      props: true,
    },
  ];
}

router.beforeEach(async (to, from, next) => {
  if (isLicenseRequiredFor(to) && !store.getters.licenseAccepted) {
    if (from.path === "/login") {
      return;
    }

    next({ path: "/login" });
  }

  next();
});

function isLicenseRequiredFor(address) {
  const licenseNotRequiredNames = ["changeDefaultPassword"];
  const licenseNotRequiredPaths = ["/login", "/password-reset"];

  if (
    licenseNotRequiredNames.includes(address.name) ||
    licenseNotRequiredPaths.includes(address.path)
  ) {
    return false;
  }

  return true;
}

export default router;
