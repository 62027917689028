/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import i18n from '../../../i18n'

export const ChannelStatus = {
  created: {code: 'created', text: i18n.t('common.channelStatus[0]'), style: 'primary'},
  started: {code: 'started', text: i18n.t('common.channelStatus[1]'), style: 'success'},
}

export const ChannelUpdateStatus = {
  draft: {code: 'draft', text: i18n.t('common.channelUpdate[0]'), style: 'primary'},
  requested: {code: 'requested', text: i18n.t('common.channelUpdate[1]'), style: 'warning'},
  error: {code: 'error', text: i18n.t('common.channelUpdate[2]'), style: 'danger'},
  success: {code: 'success', text: i18n.t('common.channelUpdate[3]'), style: 'success'},
}

export const ChannelStatuses = {}
ChannelStatuses[ChannelStatus.created.code] = {}
ChannelStatuses[ChannelStatus.created.code][ChannelUpdateStatus.draft.code] = {text: i18n.t('common.channelUpdate[0]'), style: 'primary'}
ChannelStatuses[ChannelStatus.created.code][ChannelUpdateStatus.requested.code] = {text: i18n.t('common.channelUpdate[1]'), style: 'warning'}
ChannelStatuses[ChannelStatus.created.code][ChannelUpdateStatus.error.code] = {text: i18n.t('common.channelUpdate[2]'), style: 'danger'}
ChannelStatuses[ChannelStatus.created.code][ChannelUpdateStatus.success.code] = {text: i18n.t('common.channelUpdate[3]'), style: 'success'}
ChannelStatuses[ChannelStatus.started.code] = {}
ChannelStatuses[ChannelStatus.started.code][ChannelUpdateStatus.draft.code] = {text: i18n.t('common.channelUpdate[4]'), style: 'primary'}
ChannelStatuses[ChannelStatus.started.code][ChannelUpdateStatus.requested.code] = {text: i18n.t('common.channelUpdate[5]'), style: 'warning'}
ChannelStatuses[ChannelStatus.started.code][ChannelUpdateStatus.error.code] = {text: i18n.t('common.channelUpdate[6]'), style: 'danger'}
ChannelStatuses[ChannelStatus.started.code][ChannelUpdateStatus.success.code] = {text: i18n.t('common.channelUpdate[7]'), style: 'success'}


