import { setWebsocketBuffer } from "../../services";

function handleWebsocketBuffer({ type }, state) {
  if (type !== "pushToBuffer" || type !== "clearBuffer") {
    return;
  }

  setWebsocketBuffer(state.websocket.buffer);
}

export { handleWebsocketBuffer };

