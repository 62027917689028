/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/


import httpClient from "../../api/httpClient";

export default {
  actions: {
    async getStatistics(ctx) {
      try {
        const res = await httpClient.get(`/stats/main`)
        console.log(`SUCCESS GET /statistics: `, res)

        ctx.commit('setStatistics', res.data)
        if (res.data?.widgets){
          const bcn = res.data.widgets.find(e => e.type === 'blockchainNets')
          ctx.commit('setBlockchainNets', bcn)

          const channels = res.data.widgets.find(e => e.type === 'channels')
          ctx.commit('setStatChannels', channels)

          const org = res.data.widgets.find(e => e.type === 'organizations')
          ctx.commit('setStateOrg', org)

          const inc = res.data.widgets.find(e => e.type === 'incidents')
          ctx.commit('setStateInc', inc)
        }
        return Promise.resolve(res.data)
      } catch (err) {
        console.log(`ERROR GET /statistics: `, err)
        return Promise.reject(err)
      }
    },
  },
  mutations: {
    setStatistics(state, response) {
      state.statistics = response
    },
    setBlockchainNets(state, response){
      state.blockchainNets = response
    },
    setStatChannels(state, response){
      state.channels = response
    },
    setStateInc(state, response){
      state.incidents = response
    },
    setStateOrg(state, response){
      state.organisations = response
    }
  },
  state: {
    statistics: {
      endpoint_stats: []
    },
    blockchainNets: {
      value: 0,
      ratio: 0
    },
    channels:  {
      value: 0,
      ratio: 0
    },
    incidents: {
      value: 0,
      ratio: 0
    },
    organisations:  {
      value: 0,
      ratio: 0
    }
  },
  getters: {
    getStatistics: state => state.statistics,
    getBlockChainNets: state => state.blockchainNets,
    getStatInc: state => state.incidents,
    getStatOrg: state => state.organisations,
    getStatChannels: state => state.channels
  },
}