/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import httpClient from "../../api/httpClient";
import i18n from "../../i18n";
import { makeUrlQuery } from "../../utils/system";

export default {
  actions: {
    async fetchOrganizations(ctx, params) {
      try {
        let url = "/organizations";
        if (params) {
          const paramsQuery = makeUrlQuery(params);
          if (paramsQuery) url = `${url}?${paramsQuery}`;
        }

        const res = await httpClient.get(url);
        console.log("SUCCESS GET /organizations: ", res);

        const { user } = ctx.rootState.auth;
        // TODO: vakin.d delete replace after backend will fix hardcoded value "(моя организация)"
        const organizations = res.data.map((item) => {
          addMyOrganizationPostfix(item, user);

          return item;
        });

        ctx.commit("updateOrganizations", organizations);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR GET /organizations: ", err);

        return Promise.reject(err);
      }
    },

    async createOrganization(ctx, data) {
      try {
        const res = await httpClient.post("/organizations", data);
        console.log("SUCCESS POST /organizations: ", res);
        ctx.commit("addOrganization", res.data);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR POST /organizations: ", err);

        return Promise.reject(err);
      }
    },

    async deleteOrganization(ctx, orgId) {
      try {
        const res = await httpClient.delete(`/organizations/${orgId}`);
        console.log(`SUCCESS DELETE /organizations/${orgId}: `, res);
        ctx.commit("removeOrganization", orgId);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR DELETE /organizations/${orgId}: `, err);

        return Promise.reject(err);
      }
    },

    async getOrganizationsByBlockchainId(ctx, bchId) {
      try {
        const res = await httpClient.get(`/organizations?bch_net_id=${bchId}`);
        console.log(
          `SUCCESS GET /organizations?blockchain_net_id=${bchId} `,
          res
        );

        ctx.commit("updateOrganizations", res.data);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(
          `ERROR GET /organizations?blockchain_net_id=${bchId} `,
          err
        );

        return Promise.reject(err);
      }
    },
    async getOrgByBlockchainAndChannel(ctx, data) {
      try {
        const { bchId } = data;
        const res = await httpClient.get(
          `/organizations?blockchain_net_id=${bchId}&view=channel`
        );
        return Promise.resolve(res.data);
      } catch (e) {
        console.log("get remote path error", e);
      }
    },
  },
  mutations: {
    updateOrganizations(state, organizations) {
      state.organizations = organizations;
    },

    addOrganization(state, organization) {
      state.organizations.push(organization);
    },

    removeOrganization(state, orgId) {
      const index = state.organizations.findIndex((org) => org.id === orgId);
      if (index !== -1) {
        state.organizations.splice(index, 1);
      }
    },
  },
  state: {
    organizations: [],
  },
  getters: {
    allOrganizations: (state) => {
      return state.organizations;
    },

    getOrganization: (state) => (orgId) => {
      return state.organizations.find((org) => org.id === orgId);
    },

    getCorrectOrganizationIds: (state) => {
      // correct organization has:
      // 1. CA cart
      // 2. TLSCA cert
      // 3. One or more Admin certs
      let result = [];
      for (let org of state.organizations) {
        if (
          org.msp.cacerts.length &&
          org.msp.tlscacerts.length &&
          org.msp.admincerts.length
        )
          result.push(org.id);
      }

      return result;
    },
  },
};

function addMyOrganizationPostfix(item, user) {
  if (item.name && item.name.includes("(моя организация)")) {
    item.name = item.name.replace("(моя организация)", "").trim();
  }

  if (item.id === user.org_id) {
    item.name = `${item.name || item.id} (${i18n.t(
      "organizations.myOrganization"
    )})`;
  }
}
