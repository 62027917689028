
export function showLogNotification(vueInstance, { success, type, topic_id }) {
  const message = vueInstance.$i18n.t(`message.${type}`);
  const notification = success
    ? {
        variant: "success",
        href: `/topics/${topic_id}`,
        title: vueInstance.$t("common.operationSuccess"),
      }
    : {
        variant: "danger",
        href: `/topics/${topic_id}`,
        title: vueInstance.$t("common.operationFailed"),
      };

  vueInstance.$bvToast.toast(message, notification);
}
