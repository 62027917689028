/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import Vue from "vue";
import Vuex from "vuex";
import { isLicenseAcepted } from "../services/locaStorageProvider";
import auth from "./modules/auth";
import certificate from "./modules/certificate";
import channel from "./modules/channel";
import endpoint from "./modules/endpoint";
import hosts from "./modules/hosts";
import network from "./modules/network";
import organization from "./modules/organization";
import service from "./modules/service";
import smartContracts from "./modules/smartContracts";
import statistics from "./modules/statistics";
import topic from "./modules/topic";
import transactions from "./modules/transactions";
import websocket from "./modules/websocket";

import { HAUBERK_STORE, HAUBERK_WEBSOCKET_BUFFER } from "./constants";
import {
  handleIsUpdateHostsAvailableChange,
  handleSetHostsUpdateStatus,
  handleSetLicenseAcepted
} from "./mutation-subscribers";
import { handleWebsocketBuffer } from "./mutation-subscribers/handleWebsocketBuffer";

Vue.use(Vuex);

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  initializeStore(state) {
    const store = JSON.parse(localStorage.getItem(HAUBERK_STORE));
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const licenseAccepted = isLicenseAcepted();
    const websockerBuffer = JSON.parse(
      localStorage.getItem(HAUBERK_WEBSOCKET_BUFFER)
    );

    const newState = { ...state };

    newState.auth = {
      ...newState.auth,
      ...{ token, refreshToken, licenseAccepted },
    };
    newState.endpoint = { ...newState.endpoint, ...store?.endpoint };
    newState.websocket = {
      ...newState.websocket,
      ...{ buffer: websockerBuffer || [] },
    };

    this.replaceState({ ...newState });
  },
};

const modules = {
  auth,
  certificate,
  endpoint,
  network,
  channel,
  organization,
  service,
  hosts,
  topic,
  smartContracts,
  statistics,
  transactions,
  websocket,
};

const store = new Vuex.Store({
  state,
  mutations,
  modules,
});

store.subscribe(handleIsUpdateHostsAvailableChange);
store.subscribe(handleSetHostsUpdateStatus);
store.subscribe(handleWebsocketBuffer);
store.subscribe(handleSetLicenseAcepted);

export default store;
