/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/


export const ServiceUpdateStatus = {
    draft: {code: 'draft', style: 'gray'},
    requested: {code: 'requested', style: 'orange'},
    error: {code: 'error', style: 'red'},
    success: {code: 'success', style: 'green'},
}