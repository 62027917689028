export default {
  menu: {
    login: "Логин",
    restorePassword: "Востановление пароля",
    password: "Пароль",
    logout: "Выйти",
    main: "Главная",
    tasks: "Задачи",
    networks: "Сети",
    network: "Сеть",
    allNetworks: "Все сети",
    channels: "Каналы",
    channel: "Канал",
    allChannels: "Все каналы",
    importChannel: "Импорт канала",
    createChannel: "Создание канала",
    segmentManagement: "Управление сегментом",
    services: "Сервисы",
    service: "Сервис",
    smartContracts: "Смарт-контракты",
    smartContract: "Смарт-контракт",
    allSmartContracts: "Все смарт-контракты",
    importSmartContract: "Импорт смарт-контракта",
    createSmartContract: "Создание смарт-контракта",
    connectNetwork: "Подключение к сети",
    createNetwork: "Создание сети",
    editDraft: "Редактирование черновика",
    changeNetwork: "Редактирование сети",
    changeConfig: "Изменение конфигурации",
    switchOrganization: "Переключиться",
    currentOrganization: "Текущая организация",
    networksManagement: "Управление сетями",
  },
  login: {
    auth: "Авторизация",
    signIn: "Войдите в свою учетную запись",
    enter: "Войти",
    resetPassword: "Сбросить пароль",
    acceptLicense: "Принять",
    declineLicense: "Отклонить",
    licenseAgreement: "Лицензионное соглашение",
    changePassword: "Смена пароля",
    changePasswordDescription: "Пожалуйста, смените пароль по умолчанию",
    email: "Email",
    currentPassword: "Текущий пароль",
    newPassword: "Новый пароль",
    passwordConfirmation: "Новый пароль ещё раз",
  },
  restorePassword: {
    title: "Сброс пароля",
    subtitle: "Укажите почту и мы вышлем на неё ссылку для сброса пароля",
    subtitlePasswordChange: "Придумайте новый пароль",
    email: "email",
    password: "Пароль",
    passwordConfirm: "Подтверждение пароля",
    weSendEmail: "Мы отправим письмо на адрес ",
    followTheMailLink: "Перейдите по ссылке из него, что бы продолжить",
    pageCanBeClosed: "Эту страницу можно закрыть",
    passwordChanged: "Пароль успешно изменен",
  },
  logout: {
    title: "Выход из системы",
    message: "Вы уверенны, что хотите выйти?",
    cancel: "Отмена",
    confirm: "Выйти",
  },
  dashboard: {
    networks: "Сети",
    channel: "Канал",
    organizations: "Организаций",
    incidents: "Инцидентов",
    activeChannels: "Активные каналы",
    activeBlockchainNetworks: "Активные блокчейн-сети",
    activeOrganizations: "Активные организации",
    activeIncidents: "Активные инциденты",
    node: "Узел",
    memory: "Память",
    disk: "Дисковое пространство",
    cpu: "Загрузка процессора",
    incidentsTitle: "Инциденты",
    unknown: "Неизвестно",
  },
  network: {
    item: "Сеть",
    downloadConfig: "Скачать конфигурацию",
    type: "Тип сети",
    dateTime: "Дата и время создания",
    politics: "Политика управления изменениями",
    osnProviders: "OSN-провайдеры",
    provider: "Провайдер",
    addProvider: "Добавить провайдера",
    absent: "Отсутствует",
    participants: "Участники",
    party: "Участник",
    addParty: "Добавить участника",
    importNet: "Импорт сети",
    exportNet: "Экспорт сети",
    certificates: "Сертификаты",
    ca: "УЦ (CA)",
    tlsca: "УЦ TLS (TLS CA)",
    intermediate: "Промежуточный УЦ",
    intermediateTls: "Промежуточный УЦ TLS",
    admin: "Администратор",
    peerList: "Список пиров",
    networkCard: "Сетевая карта",
    createNetwork: "Создать сеть",
    creatingNetwork: "Создание сети",
    connectNetwork: "Подключиться к сети",
    add: "Добавить",
    tableName: "Название",
    tableType: "Тип",
    status: "Статус",
    connect: "Подключение к сети",
    config: "Конфигурация сети *",
    configChoose: "Выберите конфигурацию сети",
    readError: "Ошибка чтения файла",
    connected: "Сеть подключена",
    programId: "Программный идентификатор",
    myRole: "Роль моей организации",
    selectRole: "Выберите роль",
    mspidMy: "MSPID моей организации",
    myOrgDomain: "Домен моей организации",
    platform: "Платформа",
    netPolicy: "Политика управления изменениями",
    selectPolicy: "Выберите политику",
    gost: "ГОСТ 34.2012",
    saveDraft: "Сохранить черновик",
    deleteDraft: "Удалить черновик",
    noChanges: "Нет изменений",
    cancelDraft: "Отменить изменения",
    msg: [
      "Для сохранения черновика необходимо:",
      "- Добавить OSN-Провайдер",
      "- Добавить ордерер",
      "- Добавить участника",
      "- Добавить сертификат администратора всем организациям",
      "Сохранить без публикации",
    ],
    publishMsg: [
      "Для сохранения и публикации черновика необходимо:",
      "- Добавить OSN-Провайдер",
      "- Добавить ордерер",
      "- Добавить участника",
      "- Добавить сертификат администратора всем организациям",
      "Сохранить и опубликовать",
      "Опубликовать",
    ],
    inOrg: "У организации",
    necessary: "отсутствуют необходимые сертификаты;",
    draftSaved: "Черновик сети сохранен",
    draftRemoved: "Черновик сети удален",
    org: "Организация",
    chooseOrg: "Выберите организацию",
    or: "или",
    createNewOrg: "создайте новую организацию",
    sertIsAbsent: "У выбранной организации отсутствуют корневые сертификаты",
    sertUcCa: "Сертификат УЦ (CA)",
    sertUcCaTls: "Сертификат УЦ TLS (TLS CA)",
    sertAdmin: "Сертификат администратора",
    sertAdminAdd: "Добавить сертификат администратора",
    sertRemoved: "Сертификат удален",
    ipSaved: "IP адрес сохранен",
    endpointRemoved: "Endpoint удален",
    networkDeleted: "Сеть удалена",
    ipDeleted: "IP адресс удалён",
    signTransaction: "Подписать транзакцию",
    changeNetwork: "Изменить сеть",
    cancellChanging: "Отмена изменений",
    tranzactionParamsModalTitle: "Задание параметров для отправки транзакции",
    changeTransactionTitle: "Транзакция по изменению конфигурации",
    networkNotFound: "Сеть не найдена",
    networkParameters: "Параметры сети",
    parameters: {
      admin: "Администратор сети",
      batchTimeout: "Время ожидания перед созданием блока",
      maxMessageCount:
        "Максимальное количество сообщений, разрешенных в пакете. Блок не сможет содержать больше количества сообщений, чем указанно в параметре",
      absoluteMaxBytes:
        "Максимальный размер блока. Любая транзакция, превышающая это значение, будет отклонена Ордерером",
      preferredMaxBytes:
        "Эталонный размер блока, при превышении которого Ордерер начинает формирование блока",
    },
    admin: "Администратор",
    batchTimeout: "Таймаут партии (сек.)",
    maxMessagesCount: "Максимальное количество сообщений",
    absoluteMaxBytes: "Абсолютное максимальное кол-во байт",
    preferedMaxBytes: "Предпочтительное максимальное кол-во байт",
    commomProperties: "Общие свойства",
    ordererParameters: "Параметры ордерера",
    importConfiguration: "Обновить конфигурацию",
    updatingNetworkConfiguration: "Обновление конфигурации сети",
    updateNetwork: "Обновить сеть",
    networkConfiguration: "Конфигурация сети",
    selectNetworkConfiguration: "Выберите файл с конфигурацией сети",
    configurationUpdated: "Конфигурация сети успешно обновлена",
    networkIdNotFound:
      "Идентификатор сети не найден в файле. В корневом объекте должно быть поле id",
    wrongNetworkConfiguration:
      "Вы пытаетесь загрузить неподходящую конфигурацию. Идентификатор сети в файле не совпадает с текущим идентификатором сети",
    actions: {
      title: "Действия",
      signTransaction: {
        name: "Подписать транзакцию",
        description:
          "Отправка файла конфигурационной транзакцией на удалённый хост, с установленным ключом администратора, чтобы провести операцию электронной подписи транзакции",
      },
      changeNetwork: {
        name: "Изменить сеть",
        description:
          "Запуск процесса изменения метаданных блокчейн-сети в локальной БД Hauberk Pro",
      },
      updateConfiguration: {
        name: "Обновить конфигурацию",
        description:
          "Обновление метаданных блокчейн-сети в локальной БД Hauberk Pro",
      },
      downloadConfiguration: {
        name: "Скачать конфигурацию",
        description:
          "Выгрузка метаданных блокчейн-сети из локальной БД Hauberk Pro в виде файла",
      },
      uploadNetworkMap: {
        name: "Выгрузить сетевую карту",
        description:
          "Отправка данных по DNS-именам и их IP-адресам на удалённые хосты, чтобы обновить сетевую конфигурацию узлов HLF-сети",
      },
    },
    myOrganizationName: "Наименование моей организации",
    addNewParticipant: "Добавление нового участника",
    noOrganizationAvailable: "Нет доступных для выбора организаций"
  },
  channel: {
    channel: "Канал",
    channels: "Каналы",
    placeholderSignTrans: "Выберите подписанную транзакцию",
    transChange: "Транзакция по изменению конфигурации",
    signTrans: "Подписать транзакцию",
    signTitle: "Подпись транзакции",
    titleChanges: "Изменение транзакции",
    signParams: "Задание параметров для Подписания транзакции",
    changeCancelled: "Изменение отменено",
    transSigned: "Транзакция подписана",
    changeAdded: "Изменение добавлено",
    fileReadError: "Ошибка чтения файла",
    signTransConfig: "Подпись конфигурационной транзакции",
    selectFile: "Выберите файл *",
    selectFilePl: "Выберите файл с конфигурационной транзакцией для подписи",
    addChange: "Добавить изменение +",
    typeOfChange: "Тип изменения",
    sourceData: "Исходные данные",
    newData: "Новые данные*",
    connectNet: "Подключение к сети",
    connectToNet: "Подключиться к сети",
    configNet: "Конфигурация сети *",
    selectConfig: "Выберите конфигурацию сети",
    changeChannel: "Изменить канал",
    download: "Скачать конфигурацию",
    importConfiguration: "Обновить конфигурацию",
    bchNet: "Блокчейн-сеть",
    policyChangeState: "Политика управления изменениями",
    policyLifeCycle: "Политика управления ЖЦ чейнкода",
    participants: "Участники",
    participant: "Участник",
    addParticipant: "Добавить участника",
    channelCreate: "Создание канала",
    notRunningOrEditing:
      "Блокчейн-сеть не запущена или находится в состоянии изменения",
    programId: "Программный идентификатор",
    selectPolicy: "Выберите политику",
    newChannelCreated: "Создан новый канал",
    bchChannel: "Блокчейн-сеть канала",
    createChannel: "Создать канал",
    removeChannel: "Удалить канал",
    cancelDraft: "Отменить черновик",
    saveWithoutPublish: "Сохранить без публикации",
    publishMsg: [
      "Для сохранения и публикации черновика необходимо:",
      "- Добавить участника",
      "Нет изменений",
      "Сохранить и опубликовать",
      "Сохранить",
      "Опубликовать",
    ],
    draftSaved: "Черновик канала сохранен",
    channelDraftRemoved: "Черновик канала удален",
    importChannel: "Импорт канала",
    addChannel: "Добавить канал",
    channelImported: "Канал импортирован",
    fileToImport: "Выберите файл для импорта канала",
    certificates: "Сертификаты",
    ucca: "УЦ (CA)",
    intermediateCa: "Промежуточный УЦ",
    tlsCa: "УЦ TLS (TLS CA)",
    tlsInter: "Промежуточный УЦ TLS",
    admin: "Администратор",
    channelsRow: ["Канал", "Блокчейн-сеть", "Участники", "Статус"],
    paramSendTrans: "Задание параметров для отправки транзакции",
    organization: "Организация",
    selectOrg: "Выберите организацию",
    orgAbsent: "Организации отсутствуют",
    noCert: "У выбранной организации отсутствуют корневые сертификаты",
    sertCa: "Сертификат УЦ (CA)",
    sertTls: "Сертификат УЦ TLS (TLS CA)",
    sertAdmin: "Сертификат администратора",
    ordererCannotCreateChannel: "Ордерер организации не может создать канал",
    uploadFileInFiledAbove: "Загрузите файл в поле выше",
    invalidTransactionFile:
      '"old_config_json" и "new_config_json" ключи не обнаружены в файле',
    configurationVersion: "версия конфигурации",
    groupUnderChange: "Изменяемая группа",
    channelChangePolicy: "Политика изменения канала",
    transactionSignatures: "Подписи",
    emptyTransactionSignatures: "Ещё нет ни одной подписи",
    chaincodeAdminLabel: "Администратор ЖЦ чейнкода",
    channelAdmin: "Администратор канала",
    adminBadge: "Администратор",
    chaincodeAdminBadge: "Администратор ЖЦ",
    updatingChannelConfiguration: "Обновление конфигурации канала",
    channelConfiguration: "Конфигурация канала",
    selectChannelConfiguration: "Выберите файл конфигурации канала",
    updateChannel: "Обновить канал",
    channelIdNotFound:
      "Идентификатор канала не найден в файле. В корневом объекте должно быть поле id",
    wrongChannelConfiguration:
      "Вы пытаетесь загрузить неподходящую конфигурацию. Идентификатор канала в файле не совпадает с текущим идентификатором канала",
    configurationUpdated: "Конфигурация сети успешно обновлена",
    channelDeleted: "Канал удалён",
  },
  organizations: {
    placeholderName: "Введите имя хоста",
    placeholderPort: "Введите порт",
    titlePeer: "Добавление пира",
    addPeer: "Добавить пир",
    anchorPeerManagement: "Управление якорными пирами",
    selectAnchorPeers: "Укажите якорные пиры",
    anchorPeers: "Якорные пиры",
    anchorPeersUpdating: "Процесс обновления якорных пиров запущен",
    addFabricTitle: "Добавление fabric-tools",
    domain: "Домен*",
    certificate: "Сертификат УЦ (CA)*",
    between: "Промежуточный УЦ",
    intermediateAdd: "Добавить сертификат промежуточного УЦ",
    tls: "Промежуточный УЦ TLS",
    interCert: "Добавить сертификат промежуточного УЦ TLS",
    addNew: "Создайте новую организацию",
    creating: "Создание организации",
    created: "Организация создана",
    load: "загрузите файл с сертификатом",
    selectSert: "Выберите сертификат",
    sertUcCa: "Сертификат УЦ TLS (TLS CA)*",
    myOrganization: "Моя организация",
    organizationName: "Наименование",
  },
  service: {
    services: "Серверы организации",
    service: "Сервис",
    channelName: "Название",
    channelStatus: "Статус",
    channel: "Канал",
    channels: "Каналы",
    version: "Версия",
    type: "Тип",
    bchNetwork: "Блокчейн-сеть",
    hash: "id хэш",
    host: "Хост",
    port: "Порт",
    portPlaceholder: "Введите порт",
    telnet: "Доступ по telnet",
    ssh: "Доступ по OpenSSL s_client",
    log: "Посмотреть лог ошибки",
    updatedAt: "обновлено в",
    init: "Инициализировать",
    start: "Запустить",
    stop: "Остановить",
    down: "Удалить",
    tls_certificate_local_tls_mismatch:
      "На узле в каталоге пара tls-сертификат и ключ не соответствуют друг другу",
    tlsca_certificate_local_tls_mismatch:
      "На узле в каталоге пара tls-сертификат и ca-сертификат не соответствуют друг другу",
    tlsca_certificate_local_msp_mismatch:
      "На узле в каталоге tls и в каталоге msp/tlscacerts сертификаты CA не совпадают",
    tlsca_certificate_msp_mismatch:
      "TLSCA сертификат (цепочка) в конфигурации канала и TLSCA в каталоге в локальном MSP не совпадают",
    ca_certificate_msp_mismatch:
      "CA-сертификат (цепочка) в конфигурации канала и CA (цепочка) в каталоге в локальном MSP не совпадают",
    endpoint_msp_certificate_near_to_expire:
      "Срок действия MSP-сертификата узла скоро закончится",
    endpoint_msp_certificate_expired:
      "Срок действия MSP-сертификата узла закончился",
    endpoint_tls_certificate_near_to_expire:
      "Срок действия TLS-сертификата узла скоро закончится",
    endpoint_tls_certificate_expired:
      "Срок действия TLS-сертификата узла скоро закончился",
    tlsOk: "TLS и MSP сертификаты узла в порядке",
    networksAvailability: "Сетевая доступность",
    available: "Доступен",
    availableNot: "Не доступен",
    connected: "Подключен",
    connectedNot: "Не подключен",
    connect: "Подключиться",
    smartContracts: "Смартконтракты",
    installed: "установлен",
    notInstalled: "не установлен на пире",
    badgeNotInit: "Не проинициализирован",
    badgeStopped: "Остановлен",
    badgeStarted: "Запущен",
    badgeError: "Ошибка получения статуса",
    unbind: "Непривязанные сервисы",
    addServer: "Добавить сервер",
    addNewSegment: "Добавление нового сервера ИТ сегмента",
    address: ["Адрес сервера*", "Адрес сервера"],
    bchInChange:
      "Блокчейн-сеть не запущена или находится в состоянии изменения",
    serverCreating: "Создание сервера",
    state: "Состояние",
    serverDeleted: "Сервер удален",
    endpointDeleted: "Endpoint удален",
    modalParamTitle: "Задание параметров для отправки транзакции",
    envVariable: "Переменные окружения",
    envUpdatedSuccessfully: "Переменные успешно обновлены",
    variableAlreadyExists: "Переменная с именем '#@NAME@#' уже есть",
  },
  smart: {
    smartContracts: "Смарт-контракты",
    create: "Создать смарт-контракт",
    import: "Импорт смарт-контракта",
    name: "Имя",
    type: "Тип",
    language: "Язык",
    status: "Статус",
    review: "Просмотр содержимого",
    gitSync: "Синхронизироваться с git",
    networkType: "Тип сети",
    langProg: "Язык программирования",
    noSourceCode: "Исходный код отсутствует",
    currentStatus: "Текущий статус",
    currentCommit: "Текущий коммит",
    from: "от",
    smartConst: [
      "Синхронизированно",
      "Ошибка синхронизации",
      "Обновляется",
      "Не загружено",
    ],
    smartContractAdd: "Добавление Смарт-контракта",
    nameLabel: "Название*",
    namePl: "Введите имя",
    version: "Версия*",
    chooseVersion: "Выберите версию",
    languageLabel: "Язык*",
    chooseLanguage: "Выберите язык",
    remotePath: "Удаленный путь",
    branchLabel: "Ветка*",
    branch: "Ветка",
    localPathLabel: "Локальный программный путь*",
    localPath: "Локальный путь",
    importTitle: "Импорт смарт-контаркта",
    chooseFile: "Выберите файл *",
    choosePl: "Выберите файл для импорта смарт-контракта",
    addSmartContract: "Добавить смарт-контракт",
    imported: "Смарт-контракт импортирован",
    reviewRepo: "Просмотр репозитория",
    codeGen: "Сгенерировать код",
    ver: "Вер.",
    buildDate: "дата сборки",
    hash: "хэш",
    installSm: "Установка (install) смартконтракта",
    exportSm: "Экспорт пакета со смартконтрактом",
    removePackage: "Удаление пакета",
    approveSm: "Одобрение (approve) смартконтракта",
    channel: "Канал",
    endorsement: "Endorsement-политика",
    reviewExample: "Просмотр экземпляра",
    initParams: "Init параметры",
    pdcParams: "PDC параметры",
    rule: "Правило",
    politics: "Политика одобрения",
    importPackage: "Импортировать package",
    importPackageItem: "Импорт пакета",
    fileExt: "Файл*",
    filePl: "Выберите файл для импорта пакета",
    packageImported: "Пакет импортирован",
    copyAndClose: "Скопировать в буфер и закрыть",
    forConnect: "Для подключения к репозиторию",
    copyAndAdd:
      "скопируйте ssh-ключ и добавьте к учётной записи в git репозитории",
    createExample: "Создать новый экземпляр",
    instanceParams: "Задание параметров для создания экземпляра",
    channelLabel: "Канал*",
    onInitParams: "включить init параметры",
    onPdcParams: "включить pdc параметры",
    policyText: "Политика (текст)",
    buildPackage: "Собрать package",
    addPackage: "Добавить package",
    programName: "Программное наименование*",
    modalParamTitle: "Задание параметров для отправки транзакции",
    startCommitSmart: "Запуск (commit) смартконтракта",
    addPeer: "+ добавить peer",
    instanceCreated: "Экземпляр создан",
    instanceCreationError: "Ошибка при создании экземпляра",
    packageBuildResult: "Результат сборки пакета",
    packageBuildSuccess: "Операция сборки пакета выполнилась успешно",
    packageBuildFailed: "Операция сборки пакета выполнилась с ошибкой",
    smartContractCreated: "Смарт контракт создан",
    smartContractDeleted: "Смарт контракт удалён",
    selectChannel: "Выберите канал",
    addParticipant: "Добавить участника",
    addCondition: "Добавить условие",
    showRuleImage: "Показать правило изображением",
    hideRuleImage: "Скрыть изображение правила",
    performInitialization: "Произвести инициализацию",
    addCollectiponOfPrivateData: "Добавить коллекцию закрытых данных",
  },
  topic: {
    taskStatus: "Статус задачи",
    status: [
      "Задача успешно выполнена",
      "Задача выполняется...",
      "Выполнение задачи провалилось",
    ],
  },
  footer: {
    procsy: "Промышленные криптосистемы",
    plc: "АО",
  },
  pluralValues: {
    network1: "Сеть",
    network2: "Сети",
    network3: "Сетей",
    channel1: "Канал",
    channel2: "Канала",
    channel3: "Каналов",
    org1: "Организация",
    org2: "Организации",
    org3: "Организаций",
    incidents1: "Инцидент",
    incidents2: "Инцидента",
    incidents3: "Инцидентов",
  },
  common: {
    cancel: "Отмена",
    close: "Закрыть",
    create: "Создать",
    add: "Добавить",
    save: "Сохранить",
    indicate: "Указать",
    import: "Импорт",
    send: "Отправить",
    sign: "Подписать",
    back: "Назад",
    start: "Запустить",
    export: "Экспорт",
    draft: "Черновик",
    saveDraft: "Сохранить черновик",
    active: "Активна",
    next: "Далее",
    name: "Название",
    value: "Значение",
    title: "Название",
    request: "Запросить",
    continue: "Продолжить",
    operationSuccess: "Операция выполнилась успешно",
    operationFailed: "Выполнение операции провалилось",
    in: "в",
    ip: "IP адрес",
    noData: "Нет данных",
    noChanges: "Нет изменений",
    bchSelect: [
      "Единственный участник (EXCLUSIVE)",
      "Любой участник (OR)",
      "AND",
      "Большинство участников (MAJORITY)",
    ],
    netStatus: ["Создана", "Запущена"],
    updateStatus: ["Черновик", "Идёт запуск", "Ошибка запуска", "Активна"],
    updateStatusStarted: [
      "Внесены изменения",
      "Идёт обновление",
      "Ошибка обновления",
      "Активна",
    ],
    errorServer: "Ошибка на сервере:",
    errorClient: "Ошибка в клиенте:",
    messages: [
      "Поле обязательно",
      "Имя может содержать строчные латинские буквы, цифры и знак «-»",
      "MSPID может содержать только символы цифр, букв, тире, точки",
      "Недопустимый адрес сервера",
      "Недопустимый домен",
      "Недопустимые символы. Используйте цифры в диапазоне от 0 до 65535",
    ],
    channelUpdate: [
      "Черновик",
      "В процессе развертывания",
      "Ошибка развертывания",
      "Активен",
      "Изменение",
      "Идёт обновление",
      "Ошибка обновления",
      "Активен",
    ],
    channelStatus: ["Создан", "Запущен"],
    certSaved: "Сертификат сохранен",
    certRemoved: "Сертификат удален",
    error: "Ошибка",
    greate: "Отлично",
    processing: "Обработка",
    browse: "Выбрать",
    loading: "Загрузка",
    somethingWentWrong: "Что-то пошло не так...",
    or: "или"
  },
  message: {
    CheckUpAvailability: "Проверка доступности",
    CheckUpLifecycleStatus: "Проверка Lifecycle статуса",
    ApproveChaincode: "Согласование чейнкода",
    CommitChaincode: "Запуск чейнкода",
    CopyGenesis: "Копирование genesis-блока",
    CreateChannel: "Генерация TX канала",
    CreateSmartContractPackage: "Генерация package чейнкода",
    GetStatistics: "Обновление статистики",
    GetSysSoftwareReadiness: "Проверка системного ПО",
    InitAdminConnector: "Инициализация Hauberk connector",
    InitializeEndpoint: "Инициализация сервиса",
    InstallChaincode: "Установка чейнкода",
    InstallSysSoftware: "Установка системного ПО",
    JoinChannel: "Подключение к каналу",
    LifeCycleEndpoint: "Управление ЖЦ сервиса",
    ListChaincodes: "Получение списка чейнкодов",
    ListChannels: "Получение списка каналов",
    PublishBlockchainNet: "Генерация genesis-блока",
    UpdateSmartContractLocalGitRepo: "Загрузка из git",
    CreateBranchGenerateCommitPush: "Генерация кода на базе UML",
    CreateUpdateTransaction: "Создание конфигурационной транзакции",
    GenerateSmartContractPackage: "Сборка дистрибутива СК",
    ExecuteConfigUpdateTransaction: "Выполнение конфигурационной транзакции",
    UpdateExtraHosts: "Хосты обновлены",
    GetEnvironmentVariables: "Получение переменных окружения",
    PutEnvironmentVariables: "Обновление переменных окружения",
  },
  toasts: {
    organizationChanged: "Сеть изменена",
  },
  orderer: {
    orderer: "Ордерер",
    addOrderer: "Добавить ордерера",
    dnsName: "DNS-имя",
    addingAnOrderer: "Добавление ордерера",
    port: "Порт",
    enterPort: "Введите порт",
    tlsCertificate: "Сертификат TLS",
    specifyTlsCertificate: "Укажите TLS-сертификат ордерера",
    ordererCreated: "Ордерер создан",
    certificateNotSpecified: "Сертификат не выбран!",
    ordererDeleted: "Ордерер удален",
  },
  peer: {
    created: "Пир создан",
  },
  components: {
    hauberkSelect: {
      emptyState: "Ничего не найдено. Измените условия поиска",
      selectAnItem: "Выберите позицию",
      selectedItems: "Выбрано позиций",
    },
  },
};
