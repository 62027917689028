/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import { makeUrlQuery } from "@/utils/system";
import httpClient from "../../api/httpClient";

export default {
  actions: {
    async fetchNetworks(ctx, params) {
      try {
        let url = "/blockchain-nets";
        if (params) {
          const paramsQuery = makeUrlQuery(params);
          if (paramsQuery) {
            url = `${url}?${paramsQuery}`;
          }
        }

        let res = await httpClient.get(url);
        console.log("SUCCESS blockchain-nets: ", res);
        ctx.commit("updateNetworks", res.data || []);
        ctx.dispatch("fetchUserOrganizations", null, { root: true });
        return Promise.resolve(res.data || []);
      } catch (err) {
        console.log("ERROR GET /blockchain-nets: ", err);
        ctx.commit("updateNetworks", []);
        return Promise.reject(err);
      }
    },
    async fetchStartedNetworks() {
      try {
        let res = await httpClient.get("/blockchain-nets?status=started");
        console.log("SUCCESS blockchain-nets?status=started: ", res);
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR GET /blockchain-nets?status=started: ", err);
        return Promise.reject(err);
      }
    },
    async fetchDraftNetwork(ctx, netId) {
      try {
        const res = await httpClient.get(`/blockchain-nets/${netId}/draft`);
        console.log(`SUCCESS /blockchain-nets/${netId}/draft: `, res);
        ctx.commit("updateDraftNetwork", { netId, data: res.data });
        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /blockchain-nets/${netId}/draft: `, err);
        return Promise.reject(err);
      }
    },
    async createNetwork(ctx, data) {
      try {
        const res = await httpClient.post("/blockchain-nets", data);
        console.log("SUCCESS blockchain-nets: ", res);
        ctx.commit("addNetwork", res.data);
        await this.dispatch("fetchDraftNetwork", res.data.id);
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR blockchain-nets: ", err);
        return Promise.reject(err);
      }
    },
    async saveDraftNetwork(ctx, { netId, data, withoutCheck }) {
      try {
        if (!withoutCheck) {
          ctx.commit("checkOrganizations");
        }

        const res = await httpClient.post(
          `/blockchain-nets/${netId}/draft`,
          data
        );
        console.log(`SUCCESS /blockchain-nets/${netId}/draft: `, res);
        ctx.commit("updateDraftNetwork", {
          netId: res.data.id,
          data: res.data,
        });
        ctx.commit("selectDraftNetwork", res.data.id);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /blockchain-nets/${netId}/draft: `, err);
        return Promise.reject(err);
      }
    },
    async publishDraftNetwork(ctx, { netId, data }) {
      try {
        const res = await httpClient.post(
          `/blockchain-nets/${netId}/draft/publish`,
          data
        );
        console.log(`SUCCESS /blockchain-nets/${netId}/draft/publish: `, res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /blockchain-nets/${netId}/draft/publish: `, err);

        return Promise.reject(err);
      }
    },
    async deleteNetwork(ctx, netId) {
      try {
        const res = await httpClient.delete(`/blockchain-nets/${netId}`);
        console.log(`SUCCESS  DELETE /blockchain-nets/${netId}: `, res);
        ctx.commit("removeNetwork", netId);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR DELETE /blockchain-nets/${netId}: `, err);

        return Promise.reject(err);
      }
    },
    async exportNetwork(ctx, networkId) {
      try {
        const res = await httpClient.post(
          `/blockchain-nets/${networkId}/export`
        );
        console.log(`SUCCESS /blockchain-nets/${networkId}/export: `, res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /blockchain-nets/${networkId}/export: `, err);

        return Promise.reject(err);
      }
    },
    async importNetwork(ctx, networkData) {
      try {
        const res = await httpClient.post(
          `/blockchain-nets/import`,
          networkData
        );
        console.log(`SUCCESS /blockchain-nets/import: `, res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /blockchain-nets/import: `, err);

        return Promise.reject(err);
      }
    },
    deleteOrderer(ctx, { ordererId }) {
      ctx.commit("removeOrderer", ordererId);
    },
    updateDraftOrdererOrgs(ctx, ordererOrgs) {
      ctx.commit("updateDraftOrdererOrgs", ordererOrgs);
      ctx.commit("filterOrderersByOrg", ordererOrgs);
    },
    updateDraftConsortium(ctx, consortium) {
      ctx.commit("updateDraftConsortium", consortium);
    },
    removeOrgAdminCert(ctx, { orgId, certId, type }) {
      ctx.commit("removeOrgAdminCert", { orgId, certId, type });
    },
    addOrgAdminCert(ctx, { orgId, certId, type }) {
      ctx.commit("addOrgAdminCert", { orgId, certId, type });
    },
    async createUpdateTransaction(ctx, { data, netId }) {
      try {
        const res = await httpClient.post(
          `/blockchain-nets/${netId}/create-update-transaction`,
          data
        );
        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /blockchain-nets/${netId}/draft: `, err);
        return Promise.reject(err);
      }
    },
    async cancelUpdateTransaction(ctx, netId) {
      try {
        await httpClient.post(
          `/blockchain-nets/${netId}/cancel-update-transaction`
        );
        return Promise.resolve();
      } catch (err) {
        console.log(`ERROR /blockchain-nets/${netId}/draft: `, err);
        return Promise.reject(err);
      }
    },
    async updateAnchorPeers(_, { payload, netId }) {
      try {
        const data = {
          peer_endpoint_id: payload.peer,
          orderer_endpoint_id: payload.orderer,
          admin_tool_endpoint_id: payload.fabricAdminTool,
          anchor_peers: payload.selectedPeers,
        };

        return await httpClient.post(
          `/blockchain-nets/${netId}/add-anchor-peers-transaction`,
          data
        );
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  mutations: {
    updateNetworks(state, networks) {
      state.networks = networks;
    },

    addNetwork(state, network) {
      state.networks.push(network);
    },

    removeNetwork(state, netId) {
      const index = state.networks.findIndex((network) => network.id === netId);
      if (index !== -1) {
        state.networks.splice(index, 1);
      }
    },

    checkOrganizations(state) {
      for (let field of ["orderer_organizations", "consortium", "orderers"]) {
        if (!state.draftNetwork[field]) state.draftNetwork[field] = [];

        let copy = [...state.draftNetwork[field]];
        state.draftNetwork[field].splice(0);
        for (let obj of copy) if (obj.id) state.draftNetwork[field].push(obj);
      }
    },

    updateDraftNetwork(state, { netId, data }) {
      if (!data.orderer_organizations) network.orderer_organizations = [];

      if (!data.consortium) network.consortium = [];

      if (!data.orderers) network.orderers = [];

      state.draftNetwork = Object.assign({}, state.draftNetwork, data);
      state.draftIsChanged = false;
      state.orgAdminCertsIsChanged = false;
    },

    updateDraftOrdererOrgs(state, ordererOrgs) {
      state.draftNetwork.orderer_organizations = ordererOrgs;
      state.draftIsChanged = true;
    },

    updateDraftConsortium(state, consortium) {
      state.draftNetwork.consortium = consortium;
      state.draftIsChanged = true;
    },

    addOrderer(state, newOrderer) {
      if (
        state.draftNetwork.orderers.findIndex(
          (orderer) => orderer.id === newOrderer.id
        ) === -1
      ) {
        state.draftNetwork.orderers.push(newOrderer);
        state.draftIsChanged = true;
      }
    },

    removeOrderer(state, ordererId) {
      let index = state.draftNetwork.orderers.findIndex(
        (orderer) => orderer.id === ordererId
      );
      if (index) {
        state.draftNetwork.orderers.splice(index, 1);
        state.draftIsChanged = true;
      }
    },

    filterOrderersByOrg(state, ordererOrgs) {
      let oldOrderers = [...state.draftNetwork.orderers];
      state.draftNetwork.orderers.splice(0);
      for (let orderer of oldOrderers.filter(
        (o) => ordererOrgs.findIndex((org) => org.id === o.org_id) !== -1
      )) {
        state.draftNetwork.orderers.push(orderer);
      }
    },

    selectDraftNetwork(state, netId) {
      state.draftNetwork = state.draftNetworks[netId];
      state.draftIsChanged = false;
    },

    clearDraftNetwork(state) {
      state.draftNetwork = {};
      state.draftIsChanged = false;
    },

    addOrgAdminCert(state, { orgId, certId, type }) {
      if (state.draftNetwork)
        for (let orgIn in state.draftNetwork[type]) {
          if (state.draftNetwork[type][orgIn].id === orgId) {
            if (
              state.draftNetwork[type][orgIn].msp.admincerts.findIndex(
                (cert) => cert === certId
              ) !== -1
            )
              throw new Error("Выбранный сертификат уже добавлен");

            state.draftNetwork[type][orgIn].msp.admincerts.push(certId);
            state.orgAdminCertsIsChanged = true;
          }
        }
    },

    removeOrgAdminCert(state, { orgId, certId, type }) {
      if (state.draftNetwork)
        for (let orgIn in state.draftNetwork[type]) {
          if (state.draftNetwork[type][orgIn].id === orgId) {
            const certIn = state.draftNetwork[type][
              orgIn
            ].msp.admincerts.findIndex((cert) => cert === certId);
            if (certIn !== -1) {
              state.draftNetwork[type][orgIn].msp.admincerts.splice(certIn, 1);
              state.orgAdminCertsIsChanged = true;
            }
          }
        }
    },
  },
  state: {
    networks: [],
    draftNetworks: {},
    draftNetwork: {},
    draftIsChanged: false,
    orgAdminCertsIsChanged: false,

    // currentDraft: {}
  },
  getters: {
    allNetworks: (state) => {
      return state.networks;
    },
    draftNetwork: (state) => {
      return state.draftNetwork;
    },
    draftNetworks: (state) => {
      return state.draftNetworks;
    },
    getNetwork: (state) => (netId) => {
      const index = state.networks.findIndex((network) => network.id === netId);
      
      return index !== -1 ? state.networks[index] : undefined;
    },
    selectedDraftNetwork: (state) => {
      return state.draftNetwork;
    },
    getDraftOrderersByOrg: (state) => (orgId) => {
      return state.draftNetwork.orderers.filter(
        (orderer) => orderer.org_id === orgId
      );
    },
    getOrdererOrgAdminCerts: (state) => (orgId) => {
      const draftNetwork = state.draftNetwork;
      if (draftNetwork) {
        const org = draftNetwork.orderer_organizations.find(
          (org) => org.id === orgId
        );
        return org ? org.msp.admincerts : [];
      }
      return [];
    },
    getConsortOrgAdminCerts: (state) => (orgId) => {
      const draftNetwork = state.draftNetwork;
      if (draftNetwork) {
        const org = draftNetwork.consortium.find((org) => org.id === orgId);
        return org ? org.msp.admincerts : [];
      }
      return [];
    },
    draftIsChanged: (state) => {
      return state.draftIsChanged;
    },
    orgAdminCertsIsChanged: (state) => {
      return state.orgAdminCertsIsChanged;
    },
    getDraftNetworkOrgIds: (state) => {
      let result = [];

      if (state.draftNetwork && state.draftNetwork.orderer_organizations)
        for (let org of state.draftNetwork.orderer_organizations) {
          if (!result.find((orgId) => org.id === orgId)) result.push(org.id);
        }

      if (state.draftNetwork && state.draftNetwork.consortium)
        for (let org of state.draftNetwork.consortium) {
          if (!result.find((orgId) => org.id === orgId)) result.push(org.id);
        }

      return result;
    },
  },
};
