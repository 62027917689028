export default {
  menu: {
    login: "Login",
    restorePassword: "Restore password",
    logout: "Logout",
    password: "Password",
    main: "Dashboard",
    tasks: "Tasks",
    networks: "Networks",
    network: "Network",
    allNetworks: "All Networks",
    channels: "Channels",
    channel: "Channel",
    allChannels: "All Channels",
    importChannel: "Import Channel",
    createChannel: "Create Channel",
    segmentManagement: "Segment Management",
    services: "Services",
    service: "Service",
    smartContracts: "Smart-contracts",
    smartContract: "Smart-contract",
    allSmartContracts: "All Smart-contracts",
    importSmartContract: "Import Smart-contract",
    createSmartContract: "Create Smart-contract",
    connectNetwork: "Connection to network",
    createNetwork: "Create Network",
    editDraft: "Edit Draft",
    changeNetwork: "Change network",
    changeConfig: "Change Configuration",
    switchOrganization: "Swhitch network",
    currentOrganization: "Current network",
    networksManagement: "Networks management",
  },
  login: {
    auth: "Authorization",
    signIn: "Sign in to your account",
    enter: "Login",
    resetPassword: "Reset password",
    acceptLicense: "Accept",
    declineLicense: "Decline",
    licenseAgreement: "License agreement",
    changePassword: "Password change",
    changePasswordDescription: "Please change your default password",
    email: "Email",
    currentPassword: "Current password",
    newPassword: "New password",
    passwordConfirmation: "New password again"
  },
  restorePassword: {
    title: "Password reset",
    subtitle: "Enter your email and we will send a link to reset your password",
    subtitlePasswordChange: "Create a new password",
    email: "email",
    password: "Password",
    passwordConfirm: "Confirm password",
    weSendEmail: "We will send an email to ",
    followTheMailLink: "Follow the link from the email",
    pageCanBeClosed: "This page can be closed",
    passwordChanged: "Password changed successfully"
  },
  logout: {
    title: "Logout",
    message: "Are you sure you want to logout?",
    cancel: "Cancel",
    confirm: "Logout",
  },
  dashboard: {
    networks: "Networks",
    channels: "Channels",
    organizations: "Organizations",
    incidents: "Incidents",
    activeChannels: "Active channels",
    activeBlockchainNetworks: "Active blockchain-networks",
    activeOrganizations: "Active organizations",
    activeIncidents: "Active issues",
    node: "Node",
    memory: "Memory",
    disk: "Disk Space",
    cpu: "CPU load",
    incidentsTitle: "Issues",
    unknown: "Unknown",
  },
  network: {
    item: "Network",
    downloadConfig: "Download Configuration",
    type: "Network type",
    dateTime: "Date and time creation",
    politics: "Blockchain update policy",
    osnProviders: "OSN-Providers",
    provider: "Provider",
    addProvider: "Add Provider",
    absent: "Absent",
    participants: "Participants",
    party: "Participant",
    addParty: "Add Participant",
    importNet: "Import Network",
    exportNet: "Export Network",
    certificates: "Certificates",
    ca: "CA",
    tlsca: "TLS CA",
    intermediate: "Intermediate Certs",
    intermediateTls: "Intermediate TLS",
    admin: "Admin",
    peerList: "Peer List",
    networkCard: "Network Card",
    createNetwork: "Create Network",
    creatingNetwork: "Create Network",
    connectNetwork: "Connect Network",
    add: "Add",
    tableName: "Name",
    tableType: "Type",
    status: "Status",
    connect: "Connect to network",
    config: "Network Configuration*",
    configChoose: "Select Network Configuration*",
    readError: "File read error",
    connected: "Network connected",
    programId: "Programmatic identifier",
    myRole: "Role of my organization",
    selectRole: "Select role",
    mspidMy: "MSPID of my organization",
    myOrgDomain: "Domain of my organization",
    platform: "Platform",
    netPolicy: "Change Control Policy",
    selectPolicy: "Select Policy",
    gost: "state standart 34.2012",
    saveDraft: "Save Draft",
    deleteDraft: "Remove Draft",
    cancelDraft: "Cancel Changes",
    noChanges: "No changes",
    msg: [
      "To save the draft you need:",
      "- Add OSN-Provider",
      "- Add order",
      "- Add participant",
      "- Add administrator certificate to all organizations",
      "Save without publishing",
    ],
    publishMsg: [
      "To save and publish a draft, you need to:",
      "- Add OSN-Provider",
      "- Add order",
      "- Add participant",
      "- Add administrator certificate to all organizations",
      "Save and publish",
      "Publish",
    ],
    inOrg: "At the organization",
    necessary: "required certificates are missing;",
    draftSaved: "Draft saved",
    draftRemoved: "Draft removed",
    org: "Organization",
    chooseOrg: "Select Organization",
    or: "or",
    createNewOrg: "Create new organization",
    sertIsAbsent: "The selected organization does not have root certificates",
    sertUcCa: "Certificate CA",
    sertUcCaTls: "Certificate TLS (TLS CA)",
    sertAdmin: "Administrator Certificate",
    sertAdminAdd: "Add admin certificate",
    sertRemoved: "Certificate removed",
    ipSaved: "IP address saved",
    endpointRemoved: "Endpoint removed",
    networkDeleted: "Network deleted",
    ipDeleted: "IP address deleted",
    signTransaction: "Sign transaction",
    changeNetwork: "Change network",
    cancellChanging: "Cancel changes",
    tranzactionParamsModalTitle: "Setting parameters for sending a transaction",
    changeTransactionTitle: "Configuration change transaction",
    networkNotFound: "Network not found",
    networkParameters: "Network parameters",
    parameters: {
      admin: "The network administrator",
      batchTimeout: "The amount of time to wait before creating a batch",
      maxMessageCount:
        "The maximum number of messages to permit in a batch. No block will contain more than this number of messages",
      absoluteMaxBytes:
        "The absolute maximum number of bytes allowed for the serialized messages in a batch",
      preferredMaxBytes:
        "The preferred maximum number of bytes allowed for the serialized messages in a batch",
    },
    admin: "Administrator",
    batchTimeout: "Batch timeout (sec.)",
    maxMessagesCount: "Maximum messages count",
    absoluteMaxBytes: "Absolute maximum bytes",
    preferedMaxBytes: "Prefered maximum bytes",
    commomProperties: "Common properties",
    ordererParameters: "Orderer parameters",
    importConfiguration: "Update configuration",
    updatingNetworkConfiguration: "Updating the network configuration",
    updateNetwork: "Update network",
    networkConfiguration: "Network configuration",
    selectNetworkConfiguration: "Select the network configuration file",
    configurationUpdated: "The configuration has been updated successfully",
    networkIdNotFound:
      "The network ID was not found in the file. There should be an id field in the root object",
    wrongNetworkConfiguration:
      "You are trying to upload not appropriate configuration. The network ID in the file does not match the current network ID",
    actions: {
      title: 'Actions',
      signTransaction: {
        name: "Sign the transaction",
        description:
          "Sending configuration transaction  as a file to a remote host, with an administrator key installed, to perform an operation of e-signature of the transaction",
      },
      changeNetwork: {
        name: "Change the network",
        description:
          "Starting the process of changing the metadata of the blockchain network in the local database Hauberk Pro",
      },
      updateConfiguration: {
        name: "Update the configuration",
        description:
          "Updating the metadata of the blockchain network in the local  database Hauberk Pro",
      },
      downloadConfiguration: {
        name: "Download the configuration",
        description:
          "Downloading the metadata of the blockchain network from the local database Hauberk Pro in a file",
      },
      uploadNetworkMap: {
        name: "Upload network map",
        description:
          "Sending data by DNS names and their IP addresses to remote hosts to update the network configuration of HLF network nodes",
      },
    },
    myOrganizationName: "Name of my organization",
    addNewParticipant: "Adding a new participant",
    noOrganizationAvailable: "There are no organizations"
  },
  channel: {
    channel: "Channel",
    channels: "Channels",
    placeholderSignTrans: "Choose Signed Transaction",
    transChange: "Configuration change transaction",
    signTrans: "Sign transaction",
    signTitle: "Sign transaction",
    titleChanges: "Changing a transaction",
    signParams: "Setting parameters for signing a transaction",
    changeCancelled: "Change cancelled",
    transSigned: "Transaction signed",
    changeAdded: "Changed added",
    fileReadError: "File read error",
    signTransConfig: "Configuration transaction signature",
    selectFile: "Select File *",
    selectFilePl: "Select a configuration transaction file to sign",
    addChange: "Add Change +",
    typeOfChange: "Change type",
    sourceData: "Source Data",
    newData: "New Data*",
    connectNet: "Connect to network",
    connectToNet: "Connect network",
    configNet: "Network configuration",
    selectConfig: "Select configuration",
    changeChannel: "Change Channel",
    download: "Download configuration",
    importConfiguration: "Update configuration",
    bchNet: "Blockchain-network",
    policyChangeState: "Change Management Policy",
    policyLifeCycle: "Chaincode Lifecycle Management Policy",
    participants: "Participants",
    participant: "Participant",
    addParticipant: "Add Participant",
    channelCreate: "Create Channel",
    notRunningOrEditing:
      "The blockchain network is not running or in a state of change",
    programId: "Program ID",
    selectPolicy: "Select Policy",
    newChannelCreated: "New Channel created",
    bchChannel: "Blockchain channel network",
    createChannel: "Create Channel",
    removeChannel: "Remove Channel",
    cancelDraft: "Cancel Draft",
    saveWithoutPublish: "Save without publish",
    publishMsg: [
      "To save and publish a draft, you need to:",
      "- Add a member",
      "No changes",
      "Save and publish",
      "Save",
      "Publish",
    ],
    draftSaved: "Channel Draft saved",
    channelDraftRemoved: "Channel Draft removed",
    importChannel: "Import channel",
    addChannel: "Add Channel",
    channelImported: "Channel imported",
    fileToImport: "Select file to import channel",
    certificates: "Certificates",
    ucca: "CA",
    intermediateCa: "Intermediate CA",
    tlsCa: "TLS CA",
    tlsInter: "Intermediate TLS",
    admin: "Admin",
    channelsRow: ["Channel", "Blockchain-network", "Participants", "Status"],
    paramSendTrans: "Setting parameters for sending a transaction",
    organization: "Organization",
    selectOrg: "Select Organization",
    orgAbsent: "Organizations is absent",
    noCert: "The selected organization has no root certificates",
    sertCa: "Certificate CA",
    sertTls: "Certificate TLS CA",
    sertAdmin: "Certificate Admin",
    ordererCannotCreateChannel:
      "The organization's “Orderer\" cannot create a channel",
    uploadFileInFiledAbove: "Upload the file in the field above",
    invalidTransactionFile:
      'The "old_config_json" and "new_config_json" keys were not found in the file',
    configurationVersion: "configuration version",
    groupUnderChange: "Group under change",
    channelChangePolicy: "Channel change policy",
    transactionSignatures: "Signatures",
    emptyTransactionSignatures: "Not signed yet",
    chaincodeAdminLabel: "Chaincode lifecycle administrator",
    channelAdmin: "Channel administrator",
    adminBadge: "Administrator",
    chaincodeAdminBadge: "LC administrator",
    updatingChannelConfiguration: "Updating the channel configuration",
    channelConfiguration: "Channel configuration",
    selectChannelConfiguration: "Select the channel configuration file",
    updateChannel: "Update channel",
    channelIdNotFound:
      "The channel ID was not found in the file. There should be an id field in the root object",
    wrongChannelConfiguration:
      "You are trying to upload not appropriate configuration. The channel ID in the file does not match the current channel ID",
    configurationUpdated: "The configuration has been updated successfully",
    channelDeleted: "Channel deleted",
  },
  organizations: {
    placeholderName: "Hostname",
    placeholderPort: "Port",
    titlePeer: "Add Peer",
    addPeer: "Add Peer",
    anchorPeerManagement: "Anchor peer management",
    selectAnchorPeers: "Select anchor peers",
    anchorPeers: "Anchor peers",
    anchorPeersUpdating: "Anchor peers is in proccess of updating",
    addFabricTitle: "Add Fabric-tools",
    domain: "Domain",
    certificate: "Certificate UC (СA)*",
    between: "Intermediate CA",
    intermediateAdd: "Add intermediate CA certificate",
    tls: "Intermediate UC TLS",
    interCert: "Add intermediate TLS CA certificate",
    addNew: "Create new organization",
    creating: "Creating organization",
    created: "Organization created",
    load: "upload the certificate file",
    selectSert: "Select certificate",
    sertUcCa: "Certificate TLS (TLS CA)*",
    myOrganization: "My organization",
    organizationName: "Name"
  },
  service: {
    services: "Organization servers",
    service: "Service",
    channelName: "Name",
    channelStatus: "Status",
    channel: "Channel",
    channels: "Channels",
    version: "Version",
    hash: "Id Hash",
    host: "Host",
    type: "Type",
    bchNetwork: "Blockchain network",
    port: "Port",
    portPlaceholder: "Enter the port",
    telnet: "Access by telnet",
    ssh: "Access by OpenSSL s_client",
    log: "View error log",
    updatedAt: "updated at",
    init: "Initialize",
    start: "Start",
    stop: "Stop",
    down: "Down",
    tls_certificate_local_tls_mismatch:
      "On a node in a directory, the tls-certificate and key pair do not match",
    tlsca_certificate_local_tls_mismatch:
      "On the node in the directory, the tls-certificate and ca-certificate pair do not match",
    tlsca_certificate_local_msp_mismatch:
      "CA certificates do not match on node in tls directory and msp / tlscacerts directory",
    tlsca_certificate_msp_mismatch:
      "TLSCA certificate (chain) in channel configuration and TLSCA in directory in local MSP do not match",
    ca_certificate_msp_mismatch:
      "The CA certificate (chain) in the channel configuration and the CA (chain) in the directory in the local MSP do not match",
    endpoint_msp_certificate_near_to_expire:
      "The peer MSP certificate will expire soon",
    endpoint_msp_certificate_expired: "The peer MSP certificate has expired",
    endpoint_tls_certificate_near_to_expire:
      "TLS peer certificate will expire soon",
    endpoint_tls_certificate_expired: "TLS peer certificate expired soon",
    tlsOk: "TLS and MSP peer certificates are ok",
    networksAvailability: "Networks Availability",
    available: "Available",
    availableNot: "Not Available",
    connected: "Connected",
    connectedNot: "Not Connected",
    connect: "Connect",
    smartContracts: "SmartContracts",
    installed: "Installed",
    notInstalled: "Not Installed on Peer",
    badgeNotInit: "Not initialized",
    badgeStopped: "Stopped",
    badgeStarted: "Started",
    badgeError: "Get status error",
    unbind: "Unbind Services",
    addServer: "Add Server",
    addNewSegment: "Add new server of IT segment",
    address: ["Server address*", "Server address"],
    bchInChange:
      "The blockchain network is not running or is in a state of change",
    serverCreating: "Server creating",
    state: "State",
    serverDeleted: "Server removed",
    endpointDeleted: "Endpoint removed",
    modalParamTitle: "Setting parameters for sending a transaction",
    envVariable: "Environment variables",
    envUpdatedSuccessfully: "Variables have been updated successully",
    variableAlreadyExists: "Variable with name '#@NAME@#' already exists"
  },
  smart: {
    smartContracts: "Smart-contracts",
    create: "Create Smart-contract",
    import: "Import Smart-contract",
    name: "Name",
    type: "Type",
    language: "Language",
    status: "Status",
    review: "Review contain",
    gitSync: "Sync with git",
    networkType: "Network Type",
    langProg: "Programming language",
    noSourceCode: "No source code",
    currentStatus: "Current status",
    currentCommit: "Current commit",
    from: "from",
    smartConst: ["Synchronized", "Sync error", "Loading", "Not Loaded"],
    smartContractAdd: "Add Smart-contract",
    nameLabel: "Name*",
    namePl: "Name",
    version: "Version*",
    chooseVersion: "Choose Version",
    languageLabel: "Language*",
    chooseLanguage: "Choose language",
    remotePath: "Remote path",
    branchLabel: "Branch*",
    branch: "Branch",
    localPathLabel: "Local path*",
    localPath: "Local path",
    importTitle: "Import Smart-contract",
    chooseFile: "Choose file *",
    choosePl: "Choose file to import smart-contract",
    addSmartContract: "Add Smart-contract",
    imported: "Smart-contract imported",
    reviewRepo: "Browse repository",
    codeGen: "Generate code",
    ver: "Ver.",
    buildDate: "build date",
    hash: "hash",
    installSm: "Install Smart-contract",
    exportSm: "Export package with smart contract",
    removePackage: "Remove package",
    approveSm: "Approve Smart-contract",
    channel: "Channel",
    rule: "Rule",
    endorsement: "Endorsement-policy",
    reviewExample: "Reviewing an instance",
    initParams: "Init parameters",
    pdcParams: "PDC parameters",
    politics: "Approval policy",
    importPackage: "Import package",
    importPackageItem: "Import package",
    fileExt: "File*",
    filePl: "Select file to import the package",
    packageImported: "Package imported",
    copyAndClose: "Copy in buffer and close",
    forConnect: "To connect to the repository",
    copyAndAdd: "copy the ssh key and add to the account in the git repository",
    createExample: "Create new instance",
    instanceParams: "Setting parameters for instantiation",
    channelLabel: "Channel*",
    onInitParams: "Enable init parameters",
    onPdcParams: "Enable pdc parameters",
    policyText: "Policy (text)",
    buildPackage: "Build Package",
    addPackage: "Add package",
    programName: "Software name*",
    modalParamTitle: "Setting parameters for sending a transaction",
    startCommitSmart: "Start (commit) smart contract",
    addPeer: "+ add peer",
    instanceCreated: "The instance has been created",
    instanceCreationError: "The instance creation is faild",
    packageBuildResult: "Package build result",
    packageBuildSuccess:
      "The package build operation was completed successfully",
    packageBuildFailed:
      "The package build operation was executed with an error",
    smartContractCreated: "The smart contract has been created",
    smartContractDeleted: "The smart contract has been deleted",
    selectChannel: "Select channel",
    addParticipant: "Add participant",
    addCondition: "Add condition",
    showRuleImage: "Show rule image",
    hideRuleImage: "Hide rule image",
    performInitialization: "Perform initialization",
    addCollectiponOfPrivateData: "Add a collection of private data",
  },
  topic: {
    taskStatus: "Status Task",
    status: [
      "Operation completed successfully",
      "Operation processed...",
      "Operation failed",
    ],
  },
  footer: {
    procsy: "Industrial Cryptosystems",
    plc: "JSC",
  },
  pluralValues: {
    network1: "Network",
    network2: "Networks",
    network3: "Networks",
    channel1: "Channel",
    channel2: "Channels",
    channel3: "Channels",
    org1: "Organization",
    org2: "Organizations",
    org3: "Organizations",
    incidents1: "Issue",
    incidents2: "Issues",
    incidents3: "Issues",
  },
  common: {
    cancel: "Cancel",
    create: "Create",
    close: "Close",
    add: "Add",
    save: "Save",
    indicate: "Add",
    import: "Import",
    send: "Send",
    sign: "Sign",
    back: "Back",
    start: "Start",
    export: "Export",
    draft: "Draft",
    saveDraft: "Save draft",
    active: "Active",
    next: "Next",
    name: "Name",
    value: "Value",
    title: "Title",
    request: "Request",
    continue: "Continue",
    operationSuccess: "Operation completed successfully",
    operationFailed: "Operation failed",
    in: "at",
    ip: "IP address",
    noData: "No Data",
    noChanges: "No changes",
    bchSelect: [
      "One Participant (EXCLUSIVE)",
      "Any Participant (OR)",
      "AND",
      "MAJORITY",
    ],
    netStatus: ["Created", "Started"],
    updateStatus: ["Draft", "Launch in progress", "Startup error", "Active"],
    updateStatusStarted: [
      "Changes added",
      "Updating",
      "Update error",
      "Active",
    ],
    errorServer: "Server error:",
    errorClient: "Error on client:",
    messages: [
      "Field is required",
      "It must be all lower case, less than 250 characters long and match the regular expression: [a-z][a-z0-9.-]",
      "MSPID can only contain numbers, letters, dashes, periods",
      "Invalid server address",
      "Invalid domain ",
      "Invalid characters. Use numbers between 0 and 65535",
    ],
    channelUpdate: [
      "Draft",
      "Deploying in progress",
      "Deployment failed",
      "Active",
      "Modifying",
      "Updating",
      "Update failed",
      "Active",
    ],
    channelStatus: ["Created", "Started"],
    certSaved: "Certificate saved",
    certRemoved: "Certificate removed",
    error: "Error",
    greate: "Great",
    processing: "Processing",
    browse: "Browse",
    loading: "Loading",
    somethingWentWrong: "Something went wrong...",
    or: "or"
  },
  message: {
    CheckUpAvailability: "Availability check",
    CheckUpLifecycleStatus: "Lifecycle status check",
    ApproveChaincode: "Approve chaincode",
    CommitChaincode: "Commit chaincode",
    CopyGenesis: "Copy genesis",
    CreateChannel: "TX channel generation",
    CreateSmartContractPackage: "Generation package chaincode",
    GetStatistics: "Statistics update",
    GetSysSoftwareReadiness: "System software check",
    InitAdminConnector: "Init Hauberk connector",
    InitializeEndpoint: "Service initialization",
    InstallChaincode: "Install chaincode",
    InstallSysSoftware: "Install system sofrware",
    JoinChannel: "Channel join",
    LifeCycleEndpoint: "Lifecycle endpoint control",
    ListChaincodes: "Get chaincode list",
    ListChannels: "Get channel list",
    PublishBlockchainNet: "Genesis-block generation",
    UpdateSmartContractLocalGitRepo: "Update from git",
    CreateBranchGenerateCommitPush: "UML code generation",
    CreateUpdateTransaction: "Create configuration transaction",
    GenerateSmartContractPackage: "Build distributive SC",
    ExecuteConfigUpdateTransaction: "Executing the configuration transaction",
    UpdateExtraHosts: 'Host has been updated',
    GetEnvironmentVariables: 'Recieving environment variable',
    PutEnvironmentVariables: 'Updating environment variables'
  },
  toasts: {
    organizationChanged: "Network changed",
  },
  orderer: {
    orderer: "Orderer",
    addOrderer: "Add orderer",
    dnsName: "DNS-name",
    addingAnOrderer: "Adding an orderer",
    port: "Port",
    enterPort: "Enter port",
    tlsCertificate: "TLS certificate",
    specifyTlsCertificate: "Specify the TLS certificate of the orderrer",
    ordererCreated: "The order has been created",
    certificateNotSpecified: "The certificate is not specified!",
    ordererDeleted: "The orderrer has been deleted",
  },
  peer: {
    created: "The peer has ben created",
  },
  components: {
    hauberkSelect: {
      emptyState: "No items. Change search criteria",
      selectAnItem: "Select an item",
      selectedItems: "Item(s) selected",
    },
  },
};
