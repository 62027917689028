import store from "../../store/index";
import moment from "moment";

const authInterceptor = async (request) => {  
  if (!store.getters.token) {
    return request;
  }  
  
  if (store.getters.token) {
    request.headers["Authorization"] = `Bearer ${store.getters.token}`;
  }

  request.referrerPolicy = "no-referrer";

  if (request.url.includes('refresh-token')) {
    return request;
  }

  const parsedToken = store.getters.getUser;
  const isExpired = moment.unix(parsedToken.exp).diff(moment()) < 1;

  if (!isExpired) {
    return request;
  }

  await store.dispatch('refreshToken');
  request.headers["Authorization"] = `Bearer ${store.getters.token}`;

  return request;
};

export { authInterceptor };
