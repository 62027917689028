<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <div id="app">
    <Toasts :max-messages="7" :time-out="5000"></Toasts>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getEnv, showLogNotification } from "./services";

export default {
  name: "App",
  destroyed() {
    this.disposeWebsocket();
  },
  data() {
    return {
      websocketBaseUrl: getEnv().VUE_APP_WS_URL
    }
  },
  methods: {
    ...mapActions([
      "initWebsocket",
      "disposeWebsocket",
      "initTopics",
      "disposeTopics",
    ]),
  },
  computed: {
    ...mapGetters(["token"]),
    websocketUrl: function () {
      return `${this.websocketBaseUrl}topics/ws?token=${this.token}`;
    },
  },
  watch: {
    websocketUrl: {
      handler: function (newUrl) {
        
        if (!newUrl) {
          this.disposeWebsocket();
          return;
        }
        
        
        this.initWebsocket({
          url: newUrl,
          showNotifiation: (data) => {
            showLogNotification(this, data);
          },
        });
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/style";
</style>
