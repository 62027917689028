import { HAUBERK_WEBSOCKET_BUFFER } from "../store/constants";

const LICENSE_ACEPTED = "licenseAcepted";

function getTokens() {
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");

  return [token, refreshToken];
}

function isLicenseAcepted() {
  return localStorage.getItem(LICENSE_ACEPTED) === "true";
}

function setLicenseStatus(isAcepted) {
  localStorage.setItem(LICENSE_ACEPTED, isAcepted.toString());
}

function setWebsocketBuffer(buffer) {
  localStorage.setItem(HAUBERK_WEBSOCKET_BUFFER, JSON.stringify(buffer));
}

export { getTokens, isLicenseAcepted, setLicenseStatus, setWebsocketBuffer };

