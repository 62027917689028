/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import axios from "axios";
import FileDownload from "js-file-download";

import httpClient from "../../api/httpClient";
import i18n from "./../../i18n";

import { getEnv } from "../../services";

const env = getEnv();

const httpClientWithProtocol = axios.create({
  baseURL: "http://www.plantuml.com",
  timeout: 5000,
  responseType: "blob",
});

export default {
  state: {
    smartContracts: [],
    smartContract: {},
    gitPull: { isLoading: false },
    isBuildLoading: false,
    _isCodeGenerationInProccess: false,
    _pendingTopic: "",
  },
  actions: {
    async fetchSmartContracts(ctx) {
      try {
        let res = await httpClient.get("/smart-contracts");
        console.log("SUCCESS smart-contracts: ", res);
        ctx.commit("updateSmartContracts", res.data);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR GET /smart-contracts: ", err);

        return Promise.reject(err);
      }
    },
    async createSmartContract(ctx, data) {
      try {
        let res = await httpClient.post("/smart-contracts", data);
        console.log("SUCCESS POST smart-contracts: ", res);
        await ctx.dispatch("fetchSmartContracts");

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR POST /smart-contracts: ", err);

        return Promise.reject(err);
      }
    },
    async importSmartContract(ctx, data) {
      try {
        let res = await httpClient.post(`/smart-contracts/import`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("SUCCESS POST import Smart Contract: ", res);
        await ctx.dispatch("fetchSmartContracts");

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR POST /import Smart Contract: ", err);

        return Promise.reject(err);
      }
    },
    async getSmartContractById(ctx, smartContractId) {
      try {
        let res = await httpClient.get(
          `/smart-contracts/${smartContractId}/full`
        );
        console.log("SUCCESS get smart-contracts/${data.id}/full ", res);

        ctx.commit("setSmartContract", res.data);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR POST /smart-contracts: ", err);

        return Promise.reject(err);
      }
    },
    async gitPull(ctx, smartContractId) {
      try {
        ctx.commit("setGitPull", { isLoading: true });
        let res = await httpClient.post(
          `/smart-contracts/${smartContractId}/git-pull`
        );
        console.log("SUCCESS get git pull ", res);

        ctx.dispatch("getSmartContractById", smartContractId);

        const subscription = ctx.dispatch("subscribe", {
          key: res.data.topic_id,
          handler: (data) => {
            try {
              if (data.success) {
                ctx.dispatch("getSmartContractById", data.smId);
              }

              ctx.commit("setGitPull", { isLoading: false });
            } finally {
              subscription.unsubcribe();
            }
          },
          extraPayload: {
            smId: smartContractId,
          },
        });

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR POST /smart-contracts: ", err);

        return Promise.reject(err);
      }
    },
    async exportPackage(ctx, data) {
      try {
        const { smartContractId, packageId, name } = data;
        let res = await httpClient.get(
          `/smart-contracts/${smartContractId}/package/${packageId}/export`,
          {
            responseType: "arraybuffer",
          }
        );
        console.log("SUCCESS get exportPackage", res);

        FileDownload(res.data, name);
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR GET export package: ", err);

        return Promise.reject(err);
      }
    },
    async deletePackage(ctx, data) {
      try {
        const { smartContractId, packageId } = data;
        let res = await httpClient.delete(
          `/smart-contracts/${smartContractId}/package/${packageId}`
        );
        console.log("SUCCESS delete Package ", res);

        if (res) {
          ctx.dispatch("getSmartContractById", smartContractId);
        }

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR POST /smart-contracts: ", err);

        return Promise.reject(err);
      }
    },
    async deleteSmartContract(ctx, data) {
      try {
        const { id } = data;
        let res = await httpClient.delete(`/smart-contracts/${id}`);
        console.log("SUCCESS delete smart contract ", res);

        if (res) {
          ctx.dispatch("fetchSmartContracts");
        }

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR DELETE /smart-contracts: ", err);

        return Promise.reject(err);
      }
    },
    async createBuild(ctx, data) {
      try {
        const { id, code_name, version } = data;
        const params = { code_name, version };
        ctx.commit("updateLoadingStatusBuild", true);

        let res = await httpClient.post(`/smart-contracts/${id}/build`, params);
        console.log("SUCCESS createBuild ", res);

        const subscription = await ctx.dispatch("subscribe", {
          key: res.data.topic_id,
          handler: (data) => {
            try {
              ctx.commit("updateLoadingStatusBuild", false);
              if (data.success) {
                ctx.dispatch("getSmartContractById", data.smId);
              }
            } finally {
              subscription.unsubcribe();
            }
          },
          extraPayload: { smId: id },
        });

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR POST /create build ", err);

        return Promise.reject(err);
      }
    },
    async importBuild(ctx, data) {
      try {
        const { id } = data;
        let res = await httpClient.post(
          `/smart-contracts/${id}/package-import`,
          data.data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("SUCCESS importBuild ", res);

        if (res.data) {
          ctx.dispatch("getSmartContractById", id);
        }

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR POST /package import: ", err);

        return Promise.reject(err);
      }
    },
    async approveChaincode(ctx, data) {
      try {
        const { packageId, vm, peer_endpoint_id, ...rest } = data;

        ctx.commit("updateApproveStatus", {
          instanceId: data.smart_contract_instance_id,
          packageId,
          isLoading: true,
        });

        const res = await httpClient.post(
          `/endpoints/${peer_endpoint_id}/peer/approve-chaincode`,
          rest
        );

        const subscription = ctx.dispatch("subscribe", {
          key: res.data.topic_id,
          handler: (data) => {
            try {
              ctx.commit(`update${data.type}Status`, {
                instanceId: data.instanceId,
                packageId: data.packageId,
                isLoading: false,
              });
            } finally {
              subscription.unsubcribe();
            }
          },
          extraPayload: {
            instanceId: data.smart_contract_instance_id,
            packageId,
            type: "Approve",
          },
        });
        return Promise.resolve(res.data.topic_id);
      } catch (err) {
        console.log("ERROR GET /approve chain code/", err);

        return Promise.reject(err);
      }
    },
    async installChaincode(ctx, data) {
      try {
        // todo add smart_contract_package_id
        const { packageId, vm, peer_endpoint_id, admin_tool_endpoint_id } =
          data;
        const rest = {
          admin_tool_endpoint_id,
          smart_contract_package_id: packageId,
        };

        ctx.commit("updateInstallStatus", {
          instanceId: data.smart_contract_instance_id,
          packageId,
          isLoading: true,
        });

        const res = await httpClient.post(
          `/endpoints/${peer_endpoint_id}/peer/install-chaincode`,
          rest
        );

        const subscription = await ctx.dispatch("subscribe", {
          key: res.data.topic_id,
          handler: (data) => {
            try {
              ctx.commit(`update${data.type}Status`, {
                instanceId: data.instanceId,
                packageId: data.packageId,
                isLoading: false,
              });
            } finally {
              subscription.unsubcribe();
            }
          },
          extraPayload: {
            instanceId: data.smart_contract_instance_id,
            packageId,
            type: "Install",
          },
        });
        return Promise.resolve(res.data.topic_id);
      } catch (err) {
        console.log("ERROR GET /approve chain code/", err);

        return Promise.reject(err);
      }
    },
    async commitChaincode(ctx, data) {
      try {
        const {
          packageId,
          admin_tool_endpoint_id,
          smart_contract_instance_id,
          orderer_endpoint_id,
          endorsers_ids,
        } = data;

        const rest = {
          admin_tool_endpoint_id,
          smart_contract_instance_id,
          orderer_endpoint_id,
          endorsers_ids,
        };

        ctx.commit("updateCommitStatus", {
          instanceId: smart_contract_instance_id,
          packageId,
          isLoading: true,
        });

        const res = await httpClient.post(
          `/smart-contracts/commit-chaincode`,
          rest
        );

        const subscription = await ctx.dispatch("subscribe", {
          key: res.data.topic_id,
          handler: (data) => {
            try {
              ctx.commit(`update${data.type}Status`, {
                instanceId: data.instanceId,
                packageId: data.packageId,
                isLoading: false,
              });
            } finally {
              subscription.unsubscribe();
            }
          },
          extraPayload: {
            instanceId: data.smart_contract_instance_id,
            packageId,
            type: "Commit",
          },
        });
        return Promise.resolve(res.data.topic_id);
      } catch (err) {
        console.log("ERROR GET /approve chain code/", err);

        return Promise.reject(err);
      }
    },
    async getChannelsOrganization() {
      try {
        let res = await httpClient.get(`/channels?view=organization`);
        console.log("SUCCESS getChannelsList ", res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR GET /getChannelsList: ", err);

        return Promise.reject(err);
      }
    },
    async createInstance(ctx, data) {
      const { id, vm, ...rest } = data;
      try {
        let res = await httpClient.post(
          `/smart-contracts/${id}/create-instance`,
          rest
        );
        console.log("SUCCESS createInstance ", res);

        vm.$bvToast.toast(i18n.t("smart.instanceCreated"), {
          variant: "success",
          title: `${i18n.t("common.greate")}!`,
        });

        ctx.dispatch("getSmartContractById", id);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR POST /createInstance: ", err);
        vm.$bvToast.toast(i18n.t("smart.instanceCreationError"), {
          variant: "danger",
          title: `${i18n.t("common.error")}!`,
        });
        return Promise.reject(err);
      }
    },
    async getRemotePath(ctx, data) {
      try {
        const res = await httpClient.get(`/git/get-ssh-key`);
        return Promise.resolve(res.data);
      } catch (e) {
        data.vm.$bvToast.toast(`Не найден репозиторий`, {
          variant: "danger",
          title: `Ошибка при генерации ssh ключа`,
        });
        console.log("get remote path error", e);
      }
    },
    async getFilesSourceTree(ctx, data) {
      try {
        const { id, path } = data;
        // const pathRes = path === '' ? '.' : `./${path}`;
        const res = await httpClient.get(
          `/smart-contracts/${id}/files/list?path=${path}`
        );
        return Promise.resolve(res.data);
      } catch (e) {
        console.log("get remote path error", e);
      }
    },
    async getFileSourceCode(ctx, data) {
      try {
        const { id, fileName, path } = data;
        const res = await httpClient.get(
          `/smart-contracts/${id}/files/content?path=${path}/${fileName}`
        );
        return Promise.resolve(res.data);
      } catch (e) {
        console.log("get remote path error", e);
      }
    },
    async getChaincodeSourceCode(ctx, data) {
      try {
        const { id } = data;
        const res = await httpClient.get(
          `/smart-contracts/${id}/files/chaincode-scheme`
        );
        return Promise.resolve(res.data);
      } catch (e) {
        console.log("get remote path error", e);
      }
    },
    async generateAtom(ctx, data) {
      try {
        const { id } = data;
        const res = await httpClient.post(`/smart-contracts/${id}/generate`);
        return Promise.resolve(res.data);
      } catch (e) {
        console.log("get remote path error", e);
      }
    },
    async getUmlForEndorsementView(ctx, data) {
      try {
        const res = await httpClientWithProtocol.get(`/plantuml/png/${data}`);
        return Promise.resolve(res.data);
      } catch (e) {
        console.log("get remote path error", e);
      }
    },
    async setPendingTopic(ctx, topicId) {
      ctx.commit("updatePendingTopic", topicId);
    },
  },
  mutations: {
    updateSmartContracts(state, smartContracts) {
      state.smartContracts = smartContracts;
    },
    setSmartContract(state, smartContract) {
      state.smartContract = smartContract;
    },
    setGitPull(state, response) {
      state.gitPull = { isLoading: response.isLoading };
    },
    updateApproveStatus(state, response) {
      state.smartContract = {
        ...state.smartContract,
        packages: state.smartContract.packages.map((p) =>
          p.id === response.packageId
            ? {
                ...p,
                instances: p.instances.map((instance) =>
                  instance.id === response.instanceId
                    ? {
                        ...instance,
                        isApproveChainCodeLoading: response.isLoading,
                      }
                    : instance
                ),
              }
            : p
        ),
      };
    },
    updateInstallStatus(state, response) {
      state.smartContract = {
        ...state.smartContract,
        packages: state.smartContract.packages.map((p) =>
          p.id === response.packageId
            ? { ...p, isInstallChainCodeLoading: response.isLoading }
            : p
        ),
      };
    },
    updateCommitStatus(state, response) {
      state.smartContract = {
        ...state.smartContract,
        packages: state.smartContract.packages.map((p) =>
          p.id === response.packageId
            ? {
                ...p,
                instances: p.instances.map((instance) =>
                  instance.id === response.instanceId
                    ? {
                        ...instance,
                        isCommitChainCodeLoading: response.isLoading,
                      }
                    : instance
                ),
              }
            : p
        ),
      };
    },
    updateLoadingStatusBuild(state, response) {
      state.isBuildLoading = response;
    },
    updateCodeGenerationStatus(state, isCodeGenerationInProcess) {
      state._isCodeGenerationInProccess = isCodeGenerationInProcess;
    },
    updatePendingTopic(state, topicId) {
      state._pendingTopic = topicId;
    },
  },
  getters: {
    allSmartContracts: (state) => {
      return state.smartContracts;
    },
    smartContract: (state) => {
      return state.smartContract;
    },
    getGitPull: (state) => {
      return state.gitPull;
    },
    getBuildStatus: (state) => {
      return state.isBuildLoading;
    },
    isCodeGenerationInProccess: (state) => state._isCodeGenerationInProccess,
    getPendingTopic: (state) => state._pendingTopic,
  },
};
