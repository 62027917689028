/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/


export function capitalizeFirst (val) {
  if (val)
    return val.charAt(0).toUpperCase() + val.slice(1);
  
  return ''
}