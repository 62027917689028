/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import httpClient from "../../api/httpClient";

/*
updateTransaction schema
{
  "id": "string",
  "update_type": "blockchain_consortium",
  "ref_object_id": "string",
  "config_json": "string",
  "steps": [
    {
      "type": "set_object",
      "json_path": "string",
      "json": "string",
      "ref_object_id": "string"
    }
  ],
  "signatures": [
    "string"
  ],
  "status": "string",
  "date_create": "2021-08-15T14:32:30.560Z",
  "date_last_update": "2021-08-15T14:32:30.560Z",
  "date_execute": "2021-08-15T14:32:30.560Z"
}
*/
export default {
  actions: {
    async executeTransaction(ctx, { transactionId, data }) {
      try {
        const res = await httpClient.post(
          `/transactions/${transactionId}/execute`,
          data
        );
        console.log(`SUCCESS /transactions/${transactionId}/execute: `, res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /transactions/${transactionId}/execute: `, err);

        return Promise.reject(err);
      }
    },

    async updateTransaction(ctx, { transactionId, data }) {
      try {
        const res = await httpClient.post(
          `/transactions/${transactionId}`,
          data
        );
        console.log(`SUCCESS /transactions/${transactionId}: `, res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /transactions/${transactionId}: `, err);

        return Promise.reject(err);
      }
    },

    async fetchTransaction(ctx, transactionId) {
      try {
        const res = await httpClient.get(`/transactions/${transactionId}`);
        console.log(`SUCCESS /transactions/${transactionId}: `, res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /transactions/${transactionId}: `, err);

        return Promise.reject(err);
      }
    },

    async fetchObjectTransactions(ctx, objectId) {
      try {
        const res = await httpClient.get(`/transactions/by-object/${objectId}`);
        console.log(`SUCCESS /transactions/by-object/${objectId}: `, res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /transactions/by-object/${objectId}: `, err);

        return Promise.reject(err);
      }
    },

    async signTransaction(
      ctx,
      { data, admin_tool_endpoint_id, peer_endpoint_id, transaction_id }
    ) {
      try {
        const payload = {
          data,
          admin_tool_endpoint_id,
          peer_endpoint_id,
        };

        httpClient.defaults.timeout = 15000;

        const res = await httpClient.post(
          `/transactions/${transaction_id}/sign`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(`SUCCESS /transactions/${transaction_id}/sign: `, res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /transactions/${transaction_id}/sign: `, err);

        return Promise.reject(err);
      }finally {
        httpClient.defaults.timeout = 5000;
      }
    },

    async exportTransaction(ctx, transactionId) {
      try {
        const res = await httpClient.post(
          `/transactions/${transactionId}/export`
        );
        console.log(`SUCCESS /transactions/${transactionId}/export: `, res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /transactions/${transactionId}/export: `, err);

        return Promise.reject(err);
      }
    },

    async importTransaction(ctx, data) {
      try {
        const res = await httpClient.post(`/transactions/import`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(`SUCCESS /transactions/import: `, res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /transactions/import: `, err);

        return Promise.reject(err);
      }
    },
  },
  mutations: {},

  state: {},

  getters: {},
};
