/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import jwt_decode from "jwt-decode";

import httpClient from "../../api/httpClient";
const ADMIN_ID = "00000000-0000-0000-0000-000000000000";

export default {
  actions: {
    async login(ctx, { username, password }) {
      try {
        const response = await httpClient.post("/login", {
          username,
          password,
        });

        if (response.status !== 200) {
          return Promise.reject(response.statusText);
        }

        const {
          access_token: accessToken,
          refresh_token: refreshToken,
          license_accepted,
        } = response.data;
        console.log("SUCCESS /login: ", response);
        ctx.commit("setUser", {
          accessToken,
          refreshToken,
        });
        ctx.commit("setLicenseAccepted", license_accepted);
        return Promise.resolve(response.data);
      } catch (err) {
        console.log("ERROR /login: ", err);
        return Promise.reject(err);
      }
    },
    logout(ctx) {
      ctx.commit("removeUser");
      return Promise.resolve(true);
    },
    loadToken(ctx) {
      const token = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refreshToken");
      const expired = localStorage.getItem("expired");

      if (token && expired && Number(expired) > new Date().getTime()) {
        ctx.commit("setUser", { accessToken: token, refreshToken });
        return Promise.resolve(true);
      } else {
        ctx.commit("removeUser");
        return Promise.resolve(false);
      }
    },
    async changeDefaultPassword(ctx, payload) {
      try {
        const { login, newPassword, currentPassword, email, ..._ } = payload;
        const response = await httpClient.post(
          "/users/default-password-reset",
          {
            login,
            new_password: newPassword,
            current_password: currentPassword,
            email
          }
        );

        return response.status !== 200
          ? Promise.reject(response.statusText)
          : Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchMsp(ctx) {
      try {
        const res = await httpClient.get(`/users/msp`);
        console.log(`SUCCESS GET /users/msp: `, res);

        ctx.commit("setMsp", res.data);
        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR GET /users/msp: `, err);

        return Promise.reject(err);
      }
    },
    // actions for manage organizations
    async fetchUserOrganizations(ctx) {
      try {
        const res = await httpClient.get(`/users/organizations`);
        console.log(`SUCCESS GET /users/organizations: `, res);

        ctx.commit("setOrganizations", res.data);
        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR GET /users/organizations: `, err);

        return Promise.reject(err);
      }
    },
    async switchOrganization(ctx, orgId) {
      try {
        if (!orgId) orgId = ADMIN_ID;

        ctx.dispatch("fetchUserOrganizations");
        const response = await httpClient.post(
          `/users/${orgId}/switch-organization`
        );
        console.log(
          `SUCCESS POST /users/${orgId}/switch-organization`,
          response
        );

        const { access_token: accessToken, refresh_token: refreshToken } =
          response.data;

        ctx.commit("setUser", { accessToken, refreshToken });
        return Promise.resolve(response.data);
      } catch (err) {
        console.log(`ERROR POST /users/${orgId}/switch-organization: `, err);
        return Promise.reject(err);
      }
    },
    async switchBlockchain(ctx, bchId) {
      try {
        ctx.dispatch("fetchUserOrganizations");
        const response = await httpClient.post(
          `/users/${bchId}/switch-blockchain`
        );
        console.log(`SUCCESS POST /users/${bchId}/switch-blockchain`, response);

        const { access_token: accessToken, refresh_token: refreshToken } =
          response.data;

        ctx.commit("setUser", { accessToken, refreshToken });
        return Promise.resolve(response.data);
      } catch (err) {
        console.log(`ERROR POST /users/${bchId}/switch-blockchain: `, err);
        return Promise.reject(err);
      }
    },
    async reloadToken(ctx) {
      try {
        const orgId = ctx.state.user.org_id;
        const response = await httpClient.post(
          `/users/${orgId}/switch-organization`
        );
        console.log(
          `SUCCESS POST /users/${orgId}/switch-organization`,
          response
        );

        const { access_token: accessToken, refresh_token: refreshToken } =
          response.data;

        ctx.commit("setUser", { accessToken, refreshToken });
        return Promise.resolve(response.data);
      } catch (err) {
        console.log(`ERROR POST /users/${orgId}/switch-organization: `, err);
        return Promise.reject(err);
      }
    },
    async refreshToken(ctx) {
      try {
        const response = await httpClient.post("/refresh-token", {
          refresh_token: ctx.state.refreshToken,
        });
        console.log("SUCCESS /login: ", response);
        const { access_token: accessToken, refresh_token: refreshToken } =
          response.data;

        ctx.commit("setUser", {
          accessToken,
          refreshToken,
        });
        return Promise.resolve();
      } catch (err) {
        console.log("ERROR /login: ", err);
        return Promise.reject(err);
      }
    },
  },
  mutations: {
    setUser(state, { accessToken, refreshToken }) {
      state.token = accessToken;
      state.refreshToken = refreshToken;
      const user = jwt_decode(accessToken);
      state.user = user;

      localStorage.setItem("token", accessToken);
      localStorage.setItem("refreshToken", refreshToken);

      if (!localStorage.getItem("expired")) {
        localStorage.setItem("expired", new Date().getTime() + user.exp);
      }
    },
    removeUser(state) {
      state.token = null;
      state.user = {};

      //TODO write mutation handlers to clear localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("licenseAcepted");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("expired");
    },
    setOrganizations(state, organizations) {
      state.organizations = organizations;
    },
    setMsp(state, msp) {
      state.msp = msp;
    },
    setLicenseAccepted(state, isAcepted) {
      state.licenseAccepted = isAcepted;
    },
  },
  state: {
    token: "",
    refreshToken: "",
    user: {},
    organizations: [],
    msp: {},
    licenseAccepted: false,
  },
  getters: {
    getUser: (state) => state.user,
    getOrganizations: (state) => state.organizations,
    getOrgId: (state) => state.user.org_id,
    isAdmin: (state) => state.user.org_id === ADMIN_ID,
    getMsp: (state) => state.msp,
    token: (state) => state.token,
    refreshToken: (state) => state.refreshToken,
    licenseAccepted: (state) => state.licenseAccepted,
  },
};
