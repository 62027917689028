/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/


export const sameObjects = (key, original, draft) => {
    let isSame = original[key].length === draft[key].length

    if (isSame) {
      for (let i in original[key]) {
        if (original[key][i].id !== draft[key][i].id) {
          isSame = false
          break
        }
      }
    }

    return isSame
  }

  export const sameArray = (arr1, arr2) => {
    let isSame = true
    if (Array.isArray(arr1) && Array.isArray(arr2)) {
      isSame = arr1.length === arr2.length

      if (isSame) {
        for (let i in arr1 ) {
          if (arr1[i].id !== arr2[i].id) {
            isSame = false
            break
          }
        }
      }
    }

    return isSame
  }

export const saveFile = (content, fileName) => {
  if (content != null) {
    const a = document.createElement('a')
    a.setAttribute('styly', 'display: none')
    const blob = typeof content === 'string' ? new Blob([content]) : content
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = fileName
    document.body.appendChild(a)

    a.click()
    setTimeout(() => {
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }, 1000)
  }
}

export const createFileNameWithDate = (fileName) => {
  const date = new Date().toLocaleString().split(', ').join('-').slice(0,-3)
  return`${fileName}-${date}.json`
}

export function makeUrlQuery(params) {
  // makes url query and returns 'key1=val1&key2=val2&key3=val3...'
  // from object {key1: val1, key2: val2, key3: val3...}
  let result = []

  if (params && typeof(params) === 'object')
    for (let key  of Object.keys(params))
      result.push(`${key}=${params[key]}`)
    
  return result.join('&')
}