/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import httpClient from "../../api/httpClient";

import { getEnv } from "../../services";

const env = getEnv();

export default {
  actions: {
    async fetchEndpoints(ctx, param) {
      try {
        const url = `/endpoints` + (param ? `?${param}` : "");
        const res = await httpClient.get(url);
        console.log("SUCCESS GET /endpoints: ", res);
        if (!param) {
          ctx.commit("updateEndpoints", res.data);
        }
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR GET /endpoints: ", err);

        return Promise.reject(err);
      }
    },
    async resetAllData(ctx) {
      try {
        ctx.commit("setChainCodes", {
          chainCodes: {
            items: [],
            lastUpdate: null,
            id: null,
            isLoading: false,
          },
        });
        ctx.commit("setChannels", {
          channels: { items: [], lastUpdate: null, id: null, isLoading: false },
        });
        ctx.commit("setNetworks", {
          networks: { items: [], lastUpdate: null, id: null, isLoading: false },
        });
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async getEndpointById(ctx, endpointId) {
      try {
        const res = await httpClient.get(`/endpoints/${endpointId}`);
        console.log("SUCCESS GET /endpoints/" + endpointId, res);
        ctx.commit("setBlockchainNetId", { id: res.data.blockchain_net_id });
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR GET /endpoints/" + endpointId, err);

        return Promise.reject(err);
      }
    },
    async createEndpoint(ctx, data) {
      try {
        const res = await httpClient.post("/endpoints", data);
        console.log("SUCCESS POST /endpoints: ", res);
        ctx.commit("addEndpoint", res.data);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR POST /endpoints: ", err);

        return Promise.reject(err);
      }
    },
    async deleteEndpoint(ctx, endpointId) {
      try {
        const res = await httpClient.delete(`/endpoints/${endpointId}`);
        console.log(`SUCCESS DELETE /endpoints/${endpointId}: `, res);
        ctx.commit("removeEndpoint", endpointId);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR DELETE /endpoints/${endpointId}: `, err);

        return Promise.reject(err);
      }
    },
    async getBlockchainId(ctx) {
      try {
        const res = await httpClient.get(
          `/endpoints?blockchain_net_id=${ctx.state.blockchainNetId}&status=attached&current_org=true&type=fabric-admin-tools`
        );

        let bNetID = "";
        if (Array.isArray(res)) {
          bNetID = res[0].blockchain_net_id;
        }

        return Promise.resolve(bNetID);
      } catch (err) {
        console.log("ERROR GET /endpoints/", err);

        return Promise.reject(err);
      }
    },
    async getChainCodes(ctx, { endpointId, bNetID, vm }) {
      try {
        ctx.commit("setChainCodes", {
          chainCodes: {
            ...ctx.state.chainCodes,
            isLoading: true,
          },
        });
        const data = { fabric_admin_tool_endpoint_id: bNetID };
        const res = await httpClient.post(
          `/endpoints/${endpointId}/peer/list-chaincodes`,
          data
        );

        const subscription = await ctx.dispatch("subscribe", {
          key: res.data.topic_id,
          handler: (data) => {
            try {
              ctx.dispatch("handleEndpointWsMessage", data);
            } finally {
              subscription.unsubscribe();
            }
          },
          extraPayload: {
            chainCodes: true,
            vm,
          },
        });
        return Promise.resolve(res.data.topic_id);
      } catch (err) {
        console.log("ERROR GET /endpoints/" + endpointId, err);

        return Promise.reject(err);
      }
    },
    async getChannelsList(ctx, { endpointId, bNetID, vm }) {
      try {
        ctx.commit("setChannels", {
          channels: {
            ...ctx.state.channels,
            isLoading: true,
          },
        });
        const data = { fabric_admin_tool_endpoint_id: bNetID };
        const res = await httpClient.post(
          `/endpoints/${endpointId}/peer/list-channels`,
          data
        );
        const subscription = await ctx.dispatch("subscribe", {
          key: res.data.topic_id,
          handler: (data) => {
            try {
              ctx.dispatch("handleEndpointWsMessage", data);
            } finally {
              subscription.unsubscribe();
            }
          },
          extraPayload: {
            channels: true,
            vm,
          },
        });

        return Promise.resolve(res.data.topic_id);
      } catch (err) {
        console.log("ERROR GET /endpoints/" + endpointId, err);

        return Promise.reject(err);
      }
    },
    async getAvailability(ctx, data) {
      try {
        const { endpointId, vm } = data;
        ctx.commit("setNetworks", {
          networks: {
            ...ctx.state.networks,
            isLoading: true,
          },
        });
        const res = await httpClient.post(
          `/endpoints/${endpointId}/check-up/availability`
        );

        const subscription = await ctx.dispatch("subscribe", {
          key: res.data.topic_id,
          handler: (data) => {
            try {
              ctx.dispatch("handleEndpointWsMessage", data);
            } finally {
              subscription.unsubscribe();
            }
          },
          extraPayload: {
            available: true,
            vm,
          },
        });
        return Promise.resolve(res.data.topic_id);
      } catch (err) {
        console.log("ERROR GET /endpoints/" + data.endpointId, err);

        return Promise.reject(err);
      }
    },
    async checkUpEndpointConnection(ctx, endpointId) {
      try {
        const res = await httpClient.post(
          `/endpoints/${endpointId}/check-up/connection`
        );
        console.log(
          `SUCCESS POST /endpoints/${endpointId}/check-up/connection: `,
          res
        );

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(
          `ERROR POST /endpoints/${endpointId}/check-up/connection: `,
          err
        );

        return Promise.reject(err);
      }
    },
    async checkUpLifeCycle(ctx, data) {
      try {
        const { endpointId, vm } = data;
        const res = await httpClient.post(
          `/endpoints/${endpointId}/check-up/lifecycle-status`
        );
        console.log(`/check-up/lifecycle-status: `, res);

        const subscription = await ctx.dispatch("subscribe", {
          key: res.data.topic_id,
          handler: (data) => {
            try {
              ctx.dispatch("handleEndpointWsMessage", data);
            } finally {
              subscription.unsubscribe();
            }
          },
          extraPayload: {
            lifecycle: true,
            endpointId,
            vm,
          },
        });

        ctx.commit("updateTopicId", { id: res.data.topic_id });
        return Promise.resolve(res.data.topic_id);
      } catch (err) {
        console.log(
          `ERROR POST /endpoints/${data.endpointId}/check-up/lifecycle-status: `,
          err
        );
        data.vm.$bvToast.toast(`Чекап ЖЦ не удался`, {
          variant: "error",
          title: `Ошибка чекапа ЖЦ`,
        });
        return Promise.reject(err);
      }
    },
    async lifecycleUp(ctx, data) {
      try {
        const { endpointId, vm } = data;
        const res = await httpClient.post(
          `/endpoints/${endpointId}/initialize`
        );
        console.log(`SUCCESS lifecycleUp: `, res);

        if (res) {
          const subscription = await ctx.dispatch("subscribe", {
            key: res.data.topic_id,
            handler: (data) => {
              try {
                ctx.dispatch("handleEndpointWsMessage", data);
                ctx.dispatch("checkUpLifeCycle", data);
              } finally {
                subscription.unsubscribe();
              }
            },
            extraPayload: {
              type: "statusUp",
              endpointId,
              vm,
            },
          });
        } else {
          vm.$bvToast.toast(`Инициализация сервиса провалилась`, {
            variant: "error",
            title: `Инициализация сервиса`,
          });
        }

        return Promise.resolve(res.data.topic_id);
      } catch (err) {
        console.log(`ERROR lifecycleUp: `, err);

        return Promise.reject(err);
      }
    },
    async lifecycleStart(ctx, data) {
      try {
        const { endpointId, vm } = data;
        const res = await httpClient.post(
          `/endpoints/${endpointId}/lifecycle/start`
        );
        console.log(`SUCCESS lifecycleStart: `, res);

        if (res) {
          const subscription = await ctx.dispatch("subscribe", {
            key: res.data.topic_id,
            handler: (data) => {
              try {
                ctx.dispatch("handleEndpointWsMessage", data);
                ctx.dispatch("checkUpLifeCycle", data);
              } finally {
                subscription.unsubscribe();
              }
            },
            extraPayload: {
              type: "statusStart",
              endpointId,
              vm,
            },
          });
        } else {
          vm.$bvToast.toast(`Запуск сервиса провалился`, {
            variant: "error",
            title: `Запуск сервиса`,
          });
        }

        return Promise.resolve(res.data.topic_id);
      } catch (err) {
        console.log(`ERROR lifecycleStart: `, err);

        return Promise.reject(err);
      }
    },
    async lifecycleStop(ctx, data) {
      try {
        const { endpointId, vm } = data;
        const res = await httpClient.post(
          `/endpoints/${endpointId}/lifecycle/stop`
        );
        console.log(`SUCCESS lifecycleStop: `, res);

        if (res) {
          const subscription = await ctx.dispatch("subscribe", {
            key: res.data.topic_id,
            handler: (data) => {
              try {
                ctx.dispatch("handleEndpointWsMessage", data);
                ctx.dispatch("checkUpLifeCycle", data);
              } finally {
                subscription.unsubscribe();
              }
            },
            extraPayload: {
              type: "statusStop",
              endpointId,
              vm,
            },
          });
        } else {
          vm.$bvToast.toast(`Остановка сервиса провалилось`, {
            variant: "error",
            title: `Остановка спервиса`,
          });
        }

        return Promise.resolve(res.data.topic_id);
      } catch (err) {
        console.log(`ERROR lifecycleStop: `, err);

        return Promise.reject(err);
      }
    },
    async lifecycleDown(ctx, data) {
      try {
        const { endpointId, vm } = data;
        const res = await httpClient.post(
          `/endpoints/${endpointId}/lifecycle/down`
        );
        console.log(`SUCCESS lifecycleDown: `, res);

        if (res) {
          const subscription = await ctx.dispatch("subscribe", {
            key: res.data.topic_id,
            handler: (data) => {
              try {
                ctx.dispatch("handleEndpointWsMessage", data);
                ctx.dispatch("checkUpLifeCycle", data);
              } finally {
                subscription.unsubscribe();
              }
            },
            extraPayload: {
              type: "statusDown",
              endpointId,
              vm,
            },
          });
        } else {
          vm.$bvToast.toast(`Удаление сервиса провалилось`, {
            variant: "error",
            title: `Удаление сервиса`,
          });
        }

        return Promise.resolve(res.data.topic_id);
      } catch (err) {
        console.log(`ERROR lifecycleDown: `, err);

        return Promise.reject(err);
      }
    },
    async connectChannel(ctx, channel) {
      try {
        ctx.commit("connectChannelIsLoader", {
          status: true,
          channelId: channel.channel_id,
        });
        const data = {
          channel_id: channel.channel_id,
          fabric_admin_tool_endpoint_id: channel.fabric_admin_tool_endpoint_id,
        };
        const res = await httpClient.post(
          `/endpoints/${channel.id}/peer/join-channel`,
          data
        );
        console.log(`SUCCESS connectChannel: `, res);

        const subscribe = await ctx.dispatch("subscribe", {
          key: res.data.topic_id,
          handler: (data) => {
            try {
              ctx.commit("connectChannelIsLoader", {
                status: false,
                channelId: data.channelId,
              });
            } finally {
              subscribe.unsubscribe();
            }
          },
          extraPayload: {
            channelId: channel.channel_id,
          },
        });

        return Promise.resolve();
      } catch (err) {
        console.log(`ERROR lifecycleDown: `, err);
        channel.vm.$bvToast.toast(`Подключение к каналу`, {
          variant: "danger",
          title: `Выполнение операции провалилось`,
        });
        return Promise.reject(err);
      }
    },
    lifeCycleClear(ctx) {
      ctx.commit("lifeCycleReset");
    },
    handleEndpointWsMessage(ctx, params) {
      if (params.lifecycle) {
        const lifeCycleLastUpdate = Date.now();
        if (params.response) {
          ctx.commit("lifeCycleHandler", {
            status: params.response.status,
            id: params.topic_id,
            lifeCycleLastUpdate,
          });
        }
        if (!params.success) {
          ctx.commit("lifeCycleHandler", {
            status: "error",
            id: params.topic_id,
            lifeCycleLastUpdate,
          });
        }
      }

      if (params.chainCodes) {
        if (Array.isArray(params.response)) {
          ctx.commit("setChainCodes", {
            chainCodes: {
              items: params.response,
              lastUpdate: Date.now(),
              id: params.id,
              isLoading: false,
            },
          });
        }
        if (!params.success) {
          ctx.commit("setChainCodes", {
            chainCodes: {
              items: [],
              lastUpdate: Date.now(),
              id: params.id,
              isLoading: false,
            },
          });
        }
        console.log("chain codes in ws event", params.success);
      }

      if (params.channels) {
        if (Array.isArray(params.response)) {
          ctx.commit("setChannels", {
            channels: {
              items: params.response,
              lastUpdate: Date.now(),
              id: params.id,
              isLoading: false,
            },
          });
        }
        if (!params.success) {
          ctx.commit("setChannels", {
            channels: {
              items: [],
              lastUpdate: Date.now(),
              id: params.id,
              isLoading: false,
            },
          });
        }
        console.log("channels in ws event", params.response);
      }

      if (params.available) {
        if (Array.isArray(params.response)) {
          ctx.commit("setNetworks", {
            networks: {
              items: params.response,
              lastUpdate: Date.now(),
              id: params.id,
              isLoading: false,
            },
          });
        }
        if (!params.success) {
          ctx.commit("setNetworks", {
            networks: {
              items: [],
              lastUpdate: Date.now(),
              id: params.id,
              isLoading: false,
            },
          });
        }
        console.log("setNetworks in ws event", params.response);
      }

      if (params.generateCode) {
        ctx.commit("updateCodeGenerationStatus", false, { root: true });
      }
    },
    async getIncidents(ctx, params) {
      try {
        const res = await httpClient.get(`/incidents/${params.endpointId}`);
        console.log(`SUCCESS GET /incidents/${params.endpointId}: `);

        if (res) {
          ctx.commit("setIncidents", { data: res.data });
        }
        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR POST /incidents/${params.endpointId}: `, err);

        return Promise.reject(err);
      }
    },
    async updateEndpoint(ctx, endpointId) {
      return httpClient
        .post(`/endpoints/${endpointId}/docker/update-extra-hosts`)
        .then(({ data }) => ({ endpointId, topicId: data.topic_id }))
        .catch((error) => ({ endpointId, topicId: 0, error }));
    },
    updateIsUpdateHostsAvailable(ctx, value) {
      ctx.commit("setIsUpdateHostsAvailable", value);
    },
    updateHostsUpdateStatus(ctx, value) {
      ctx.commit("setHostsUpdateStatus", value);
    },
  },
  mutations: {
    updateEndpoints(state, endpoints) {
      state.endpoints = endpoints;
    },
    addEndpoint(state, endpoint) {
      state.endpoints.push(endpoint);
    },
    removeEndpoint(state, endpointId) {
      const index = state.endpoints.findIndex(
        (endpoint) => endpoint.id === endpointId
      );
      if (index !== -1) {
        state.endpoints.splice(index, 1);
      }
    },
    addEndpointSocket(state, { endpointId, socket }) {
      state.endpointSockets[endpointId] = {
        socket: socket,
        status: null,
      };
    },
    lifeCycleHandler(state, { status, id, lifeCycleLastUpdate }) {
      state.lifecycle = { status, id, lifeCycleLastUpdate };
    },
    lifeCycleReset(state) {
      state.lifecycle = { status: "", id: null, lastUpdate: null };
    },
    updateTopicId(state, { id }) {
      state.topicId = id;
    },
    setBlockchainNetId(state, { id }) {
      state.blockchainNetId = id;
    },
    setChainCodes(state, { chainCodes }) {
      state.chainCodes = chainCodes;
    },
    setChannels(state, { channels }) {
      state.channels = channels;
    },
    setNetworks(state, { networks }) {
      state.networks = networks;
    },
    connectChannelIsLoader(state, { status, channelId }) {
      state.channelId = channelId;
      state.isChannelConnectLoading = status;

      if (!status) {
        state.connectedIdList.push(channelId);

        const index = state.channels.items.findIndex(
          (ch) => ch.id === channelId
        );
        if (index !== -1) {
          state.channels.items[index].is_joined = true;
        }
      }
    },
    setIncidents(state, { data }) {
      state.incidents = data;
    },
    setIsUpdateHostsAvailable(state, value) {
      state.isUpdateHostsAvailable = value;
    },
    setHostsUpdateStatus(state, updateState) {
      state.hostsUpdateStatus = updateState;
    },
  },
  state: {
    networks: {
      items: [],
      lastUpdate: null,
      isLoading: false,
    },
    channels: {
      items: [],
      lastUpdate: null,
      isLoading: false,
    },
    chainCodes: {
      items: [],
      lastUpdate: null,
      isLoading: false,
    },
    blockchainNetId: null,
    topicId: null,
    lifecycle: { status: "", id: null, lifeCycleLastUpdate: null },
    endpoints: [],
    endpointSockets: {},
    isChannelConnectLoading: false,
    channelId: null,
    connectedIdList: [],
    incidents: [],
    isUpdateHostsAvailable: false,
    hostsUpdateStatus: null,
  },
  getters: {
    allEndpoints: (state) => {
      return state.endpoints;
    },
    getEndpoint: (state) => (endpointId) => {
      const index = state.endpoints.findIndex(
        (endpoint) => endpoint.id === endpointId
      );
      if (index !== -1) {
        return state.endpoints[index];
      }
    },
    orgEndpoints: (state) => (orgId) => {
      return state.endpoints.filter((endpoint) => endpoint.org_id === orgId);
    },
    filterEndpoints: (state) => (orgId, type) => {
      console.log("filterEndpoints:", orgId, type);
      return state.endpoints.filter(
        (endpoint) => endpoint.org_id === orgId && endpoint.type === type
      );
    },
    getLifeCycle: (state) => {
      return state.lifecycle;
    },
    getEndpointTopicId: (state) => {
      return state.topicId;
    },
    getChainCodes: (state) => {
      return { chainCodes: state.chainCodes };
    },
    getChannels: (state) => {
      return { channels: state.channels };
    },
    getNetworks: (state) => {
      return { networks: state.networks };
    },
    getChannelsConnects: (state) => {
      return {
        isChannelConnectLoading: state.isChannelConnectLoading,
        channelId: state.channelId,
        connectedIdList: state.connectedIdList,
      };
    },
    getIncidents: (state) => {
      return state.incidents;
    },
    isUpdateHostsAvailable: (state) => state.isUpdateHostsAvailable,
    hostsUpdateStatus: (state) => state.hostsUpdateStatus,
  },
};
