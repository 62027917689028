import { HAUBERK_STORE } from "../constants";

function handleIsUpdateHostsAvailableChange(mutation, state) {
  
  if (mutation.type !== "setIsUpdateHostsAvailable") {
    return;
  }
  const store = JSON.parse(localStorage.getItem(HAUBERK_STORE)) ?? {};
  store.endpoint = {
    ...store.endpoint,
    ...{ isUpdateHostsAvailable: mutation.payload },
  };

  localStorage.setItem(HAUBERK_STORE, JSON.stringify(store));
}

export { handleIsUpdateHostsAvailableChange };
