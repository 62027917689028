/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import axios from "axios";

import { getEnv } from "../services";
import {
  authInterceptor,
  authErrorInterceptor,
} from "./interceptors";

const env = getEnv();

const httpClient = axios.create({
  baseURL: env.VUE_APP_BASE_URL,
  timeout: 5000,
  headers: {
    "Cache-Control": "no-cache",
    "Access-Control-Allow-Origin": "*",
  },
});

httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.response.use(
  (response) => response,
  authErrorInterceptor
);


export default httpClient;
