/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/


import httpClient from '../../api/httpClient'
import {makeUrlQuery} from '@/utils/system'

export default {
  actions: {

    async fetchCertificates(ctx) {
      try {
        const res = await httpClient.get('/certificates')
        console.log('SUCCESS GET /certificates: ', res)
        ctx.commit('updateCertificates', res.data)

        return Promise.resolve(res.data)

      } catch (err) {
        console.log('ERROR GET /certificates: ', err)

        return Promise.reject(err)
      }
    },

    async createCertificate(ctx, data) {
      try {
        const res = await httpClient.post('/certificates', data)
        console.log('SUCCESS POST /certificates: ', res)
        ctx.commit('addCertificate', res.data)

        return Promise.resolve(res.data)

      } catch (err) {
        console.log('ERROR POST /certificates: ', err)

        return Promise.reject(err)

      }
    },

    async deleteCertificate(ctx, {certId, params}) {
      let url = `/certificates/${certId}`

      try {

        if (params) {
          const paramsQuery = makeUrlQuery(params)
          if (paramsQuery)
            url = `${url}?${paramsQuery}`
        }

        const res = await httpClient.delete(url)
        console.log(`SUCCESS DELETE /certificates/${certId}: `, res)
        ctx.commit('removeCertificate', certId)

        return Promise.resolve(res.data)

      } catch (err) {
        console.log(`ERROR DELETE /certificates/${certId}: `, err)

        return Promise.reject(err)
      }
    }
  },
  mutations: {
    updateCertificates(state, certificates) {
      state.certificates = certificates
    },

    addCertificate(state, certificate) {
      state.certificates.push(certificate)
    },

    removeCertificate(state, certId) {
      const index = state.certificates.findIndex(cert=>cert.id===certId)
      if (index !== -1) {
        state.certificates.splice(index, 1)
      }
    },

  },
  state: {
    certificates: [],
  },
  getters: {
    allCertificates: state => {
      return state.certificates
    },

    getCertificate: state => certId => {
      const index = state.certificates.findIndex(cert => cert.id===certId)
      if (index !== -1) {
        return state.certificates[index]
      }
    },

    orgCertificates: state => orgId => {
      return state.certificates.filter(cert => cert.org_id===orgId)
    },

    getCertificateByPem: state => pem => {
      return state.certificates.find(cert => cert.pem===pem)
    }
  },
}
