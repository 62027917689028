/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/

import { makeUrlQuery } from "@/utils/system";
import { ChannelUpdateStatus } from "@/views/hauberk/channels/constants";
import httpClient from "../../api/httpClient";


export default {
  actions: {
    async fetchChannels(ctx, params) {
      try {
        let url = "/channels";
        if (params) {
          const paramsQuery = makeUrlQuery(params);
          if (paramsQuery) url = `${url}?${paramsQuery}`;
        }

        let res = await httpClient.get(url);
        console.log("SUCCESS /channels: ", res);
        ctx.commit("updateChannels", res.data);

        if (Array.isArray(res.data)) {
          res.data.map((channel) => {
            if (channel.update_status === ChannelUpdateStatus.requested.code) {
              ctx.dispatch("openWsConnectionByChannelId", channel.id);
            }
          });
        }

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR GET /channels: ", err);

        return Promise.reject(err);
      }
    },
    async fetchChannel(ctx, channelId) {
      try {
        const res = await httpClient.get(`/channels/${channelId}`);
        console.log(`SUCCESS /channels/${channelId}: `, res);
        ctx.commit("updateCurrentChannel", res.data);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /channels/${channelId}: `, err);

        return Promise.reject(err);
      }
    },
    async fetchDraftChannel(ctx, channelId) {
      try {
        const res = await httpClient.get(`/channels/${channelId}/draft`);
        console.log(`SUCCESS /channels/${channelId}/draft: `, res);
        ctx.commit("updateDraftChannel", res.data);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /channels/${channelId}/draft: `, err);

        return Promise.reject(err);
      }
    },
    async createChannel(ctx, data) {
      try {
        const res = await httpClient.post("/channels", data);
        console.log("SUCCESS created channel: ", res);
        ctx.commit("addChannel", res.data);
        // await this.dispatch('fetchDraftChannel', res.data.id)

        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR channel: ", err);

        return Promise.reject(err);
      }
    },
    async deleteChannel(ctx, data) {
      try {
        const { id, type } = data;
        const res = await httpClient.delete(`/channels/${id}`);
        console.log(`SUCCESS  DELETE /channels/${id}: `, res);
        if (type === "draft") {
          ctx.commit("removeChannel", id);
        } else {
          ctx.dispatch("fetchChannels", { view: "organization" });
        }

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR DELETE /channels/${data.id}: `, err);

        return Promise.reject(err);
      }
    },
    async deleteDraftChannel(ctx, id) {
      try {
        const res = await httpClient.delete(`/channels/${id}/draft`);
        console.log(`SUCCESS  DELETE /channels/${id}/draft: `, res);
        ctx.commit("removeChannel", id);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR DELETE /channels/${data.id}/draft: `, err);

        return Promise.reject(err);
      }
    },
    async saveDraftChannel(ctx, { channelId, data }) {
      try {
        const res = await httpClient.post(`/channels/${channelId}/draft`, data);
        console.log(`SUCCESS /channels/${channelId}/draft: `, res);
        ctx.commit("updateDraftChannel", {
          channelId: res.data.id,
          data: res.data,
        });

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /channels/${channelId}/draft: `, err);

        return Promise.reject(err);
      }
    },
    async publishDraftChannel(ctx, { channelId, data }) {
      // Publish draft Channel
      // Returns ID of Topic
      try {
        const res = await httpClient.post(
          `/channels/${channelId}/publish`,
          data
        );

        console.log(`SUCCESS /channels${channelId}/publish: `, res);

        const channelIdTopicId = {
          channelId: channelId,
          topicId: res.data.topic_id,
        };

        ctx.commit("addTopicIdToChannel", channelIdTopicId);

        return Promise.resolve(res.data.topic_id);
      } catch (err) {
        console.log(`ERROR /channels/${channelId}/publish: `, err);

        return Promise.reject(err);
      }
    },
    async CreateUpdateTransactChannel(ctx, { channelId, data }) {
      // Update channel transaction
      // Returns ID of Topic
      try {
        const res = await httpClient.post(
          `/channels/${channelId}/create-update-transaction`,
          data
        );

        console.log(
          `SUCCESS /channels${channelId}/create-update-transaction: `,
          res
        );

        const channelIdTopicId = {
          channelId: channelId,
          topicId: res.data.topic_id,
        };

        ctx.commit("addTopicIdToChannel", channelIdTopicId);

        return Promise.resolve(res.data.topic_id);
      } catch (err) {
        console.log(
          `ERROR /channels/${channelId}/create-update-transaction: `,
          err
        );

        return Promise.reject(err);
      }
    },
    async CancelUpdateTransactChannel(ctx, channelId) {
      // Update channel transaction
      // Returns ID of Topic
      const url = `/channels/${channelId}/cancel-update-transaction`;
      try {
        const res = await httpClient.post(url);

        console.log(`SUCCESS ${url}: `, res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR ${url}: `, err);

        return Promise.reject(err);
      }
    },
    async getTopicByIdForChannel(ctx, channelId) {
      try {
        const res = await httpClient.get(`/topics/by-object/${channelId}`);
        console.log(`SUCCESS GET /topics/by-object/${channelId}: `, res);
        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR GET /topics/by-object//${channelId}: `, err);
        return Promise.reject(err);
      }
    },
    async openWsConnectionByChannelId(ctx, channelId) {
      const { topic_id } = await ctx.dispatch(
        "getTopicByIdForChannel",
        channelId
      );

      const channelIdTopicId = {
        channelId: channelId,
        topicId: topic_id,
      };

      ctx.commit("addTopicIdToChannel", channelIdTopicId);

      const subscription = await ctx.dispatch("subscribe", {
        key: topic_id,
        handler: (data) => {
          try {
            if (data.success) {
              ctx.commit("changeStatusToSucces", data.topic_id);
            } else {
              const errorMessage = data.errors.join("\n");
              ctx.commit("changeStatusToError", {
                topicId: data.topic_id,
                errorMessage,
              });
            }
          } finally {
            subscription.unsubscribe();
          }
        },
      });
    },
    async exportChannel(ctx, channel) {
      try {
        const res = await httpClient.post(
          `/channels/${channel.id}/export`,
          channel
        );
        console.log(`SUCCESS /channels/${channel.id}/export: `, res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /channels/${channel.id}/export: `, err);

        return Promise.reject(err);
      }
    },
    async importChannel(ctx, channel) {
      try {
        const res = await httpClient.post(`/channels/import`, channel);
        console.log(`SUCCESS /channels/import: `, res);

        return Promise.resolve(res.data);
      } catch (err) {
        console.log(`ERROR /channels/import: `, err);

        return Promise.reject(err);
      }
    },
    async signTransactionChannel(ctx, props) {
      try {
        const { adminToolId, data } = props;
        let res = await httpClient.post(
          `/endpoints/${adminToolId}/peer/channel-signconfigtx`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("SUCCESS POST sign transaction: ", res);
        return Promise.resolve(res.data);
      } catch (err) {
        console.log("ERROR POST /import Smart Contract: ", err);
        return Promise.reject(err);
      }
    },
  },

  mutations: {
    updateChannels(state, channels) {
      state.channels = channels;
    },

    addChannel(state, channel) {
      state.channels = [...state.channels, channel];
    },

    updateCurrentChannel(state, data) {
      state.currentChannel = data;
    },

    updateDraftChannel(state, data) {
      state.draftChannel = data;
    },

    removeChannel(state, channelId) {
      state.channels = state.channels.filter((item) => item.id === channelId);
    },

    addTopicIdToChannel(state, { channelId, topicId }) {
      let channel = state.channels.find((channel) => channel.id === channelId);
      if (channel) {
        channel = { ...channel, topicId };
        state.channels = [
          ...state.channels.filter((item) => item.id !== channelId),
          channel,
        ];
      }
    },

    changeStatusToSucces(state, topicId) {
      let channel = state.channels.find(
        (channel) => channel.topicId === topicId
      );
      if (channel) {
        const channelId = channel.id;
        channel = {
          ...channel,
          update_status: ChannelUpdateStatus.success.code,
        };
        state.channels = [
          ...state.channels.filter((item) => item.id !== channelId),
          channel,
        ];
      }
    },

    changeStatusToError(state, { topicId, errorMessage }) {
      let channel = state.channels.find(
        (channel) => channel.topicId === topicId
      );
      if (channel) {
        const channelId = channel.id;
        channel = {
          ...channel,
          update_status: ChannelUpdateStatus.error.code,
          errorMessage,
        };
        state.channels = [
          ...state.channels.filter((item) => item.id !== channelId),
          channel,
        ];
      }
    },

    deleteErrorMessageFromChannel(state, channelId) {
      let channel = state.channels.find((channel) => channel.id === channelId);
      if (channel && channel.errorMessage != null) {
        delete channel.errorMessage;
        state.channels = [
          ...state.channels.filter((item) => item.id !== channelId),
          channel,
        ];
      }
    },
  },
  state: {
    channels: [],
    draftChannel: {},
    currentChannel: {},
  },
  getters: {
    allChannels: (state) => {
      return state.channels;
    },
    currentChannel: (state) => {
      return state.currentChannel;
    },
    getDraftChannel: (state) => () => {
      return state.draftChannel;
    },
  },
};
